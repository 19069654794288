@import './src/variables.scss';
.tabRoot{
    .tabList{
        background: transparent;
        margin-bottom: 24px;
    }
    .tabItem{
        text-transform: none;        
        font-weight: 600;
        font-size: 1.3rem;
        text-align: left;
        color: $primary;
        min-width: auto;
        opacity: 0.5;   
        min-height: unset; 
        margin-right: 22px;
    }
    .tabLabel{
        padding: 6px;
    }
    .tabIndicator{
        @include horizontal-gradient($gradientStart, $gradientEnd);
        height: 3px;
        border-radius: 3px;
        bottom: 10px;
    }
}
