.MainInfoAdmin{
    label{
        font-size:1.25rem;
    }
    input, select{
        font-size: 1.3rem;
        height: unset;
    }
    #select-county, #select-team{
        font-size: 1.3rem;
    }
}