.dialog-title {
  display: flex;
  justify-content: space-between;
}

.content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .webcam-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .button-row {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 4px;
    }
  }
}

.camera-unavailable-alert {
  margin-bottom: 12px;
}
