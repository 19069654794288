@import '../../../../variables.scss';
.courseAttenance{
    .courseAttendance_selectAll{
        padding-right: 11px;
    }
    .courseAttendance_item{
        border-bottom: 1px solid $border;
        padding-bottom: 8px;
        padding-top: 8px;

        .courseAttendance_image{
         background: $lightGrey;
         border-radius: 4px;
         border: 1px solid $border;
         overflow: hidden;
         display: inline-block;
         margin-right: 16px;
         width: 45px;
         height: 45px;
         vertical-align: top;

         img{
             width: 100%;
         }
        }
    }
}