@import './src/variables.scss';

.privateMenuPaper.Paper{
  background: $darkBackground;
  position: relative;
  max-width: 250px;
}
.privateMenu {
  color: $purewhite;
  background: $darkBackground;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0px;
  display: inline-block;
  user-select: none;
  transition: all 0.2s ease-in-out;
  width: 250px;
  
  // @media screen and (max-width: $sm) {
  //    left: -250px;
  //  }
  /* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1); 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3); 
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4); 
}
.privateMenu_wrapper{
  position: absolute;
  display: inline-block;
  width: 250px;
  top: 82px;
  bottom: 52px;
  vertical-align: top;
  overflow: hidden;

  &:hover{
    overflow: auto;
  }
}
.privateMenu_content {
  padding: 20px;
}
.privateMenu_top {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 18px;
  margin: 0 20px;
  border-bottom: 1px solid $purewhite;
}
.privateLogo{
    width: 42px;
    height: 42px;
    @include horizontal-gradient($gradientStart, $gradientEnd);
    border-radius: 6px;   
    vertical-align: top;
    margin-right: 12px;
    font-weight: $bold;
}
.privateName {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 22px;
  display: inline-block;
  max-width: 156px;
}
.privateMenu_linkGroup{
  margin: 14px 0;
}
.privateMenu_linkGroupTitle{
  font-weight: bold;
  font-size: 1.1rem;
  color: $lightGrey;
  text-transform: uppercase;
  margin-bottom:8px;
  }
.privateMenu_link{
  padding: 11px 19px;
  display: block;
  outline: none;

  &:hover{
    color: darken($purewhite, 20%);
  }
  &.active{
    background: rgba(255,255,255,0.1);
    border-radius: 4px;
  }
}
.expansionPanel.privateMenu_expansionPanel{
  padding: 11px 19px;
  display: block;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  margin-top: 0;
  border-radius: 4px;
  color: $purewhite;

  &.expanded{
    background-color: rgba(255,255,255,0.1);
  }
  &:hover{
    color: darken($purewhite, 20%);
  }
  &.active{
    background: rgba(255,255,255,0.1);
    border-radius: 4px;
  }
  &:before{
    display: none;
  }
  .privateMenu_expansionPanel_title{
    padding: 0;
    min-height: unset;
    
    > div{
      margin: 0;
    }
    .expandIcon{
      padding: 0;
    }
    .expansionIcon{
      color: $purewhite;
      font-size: 1.3rem;
    }
  }
  .privateMenu_expansionPanel_content{
    padding: 16px 0 16px 32px;
    font-size: 1.3rem;
    display: block;

    .privateMenu_expansionLink{
      display: block;
      padding: 12px 0;
      position: relative;
      color: $lightGrey;
    }
    a.active .privateMenu_expansionLink{
      color: $purewhite;
    }
    a.active .privateMenu_expansionLink:before{
      content: "";
      width: 5px;
      height: 5px;
      background: $brand;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      top: 44%;
      left: -12px;
    }
  }
}

.privateMenu_link-icon{
  display: inline-block;
  font-size: 1.2rem;
}
.privateMenu_link-label{
  display: inline-block;
  margin-left: 16px;
  font-size: 1.3rem;
}
.privateMenu_bottom{
  border-top: 1px solid $purewhite;
  margin: 0 20px;
  position: absolute;
  right: 0;
  bottom:0;
  left: 0;
  background: $darkBackground;
}
.privateMenu_Bottomlink{
  padding: 16px 19px;
  display: block;
  cursor: pointer;
}
}