@import './src/variables.scss';
.blogCard_Content{
    p{
        font-size: 1.6rem;
        line-height: 22px;
        text-align: left;
        color: $mediumGrey;
    }
}
.author{

    p{
        font-size: 1.6rem;
        color: $primary;
    }
    .author_Image{
        width: 40px;
        height: 40px;
        background: $placeholder;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
    }
    .author_Text{
        display: inline-block;
        padding-left: 12px;
        vertical-align: middle;
    }
}
.blogs_recent{
    margin-top: 40px;

    .blogCard_image{
        height: 180px;
        background: $border;
        width: 100%;
        overflow: hidden;
        border-radius: 6px 6px 0 0;
    }
}
.blogs_hero{
    margin-top: 40px;

    .blogCard_image{
        height: 100%;
        background: $border;
        width: 100%;
        overflow: hidden;
        border-radius: 0 6px 6px 0;
    }
}