@import "../../../variables.scss";
.react-datepicker__portal {
  background: rgba(0, 0, 0, 0.5);
}
.react-datepicker__input-container {
  display: block;
}
.Chabber_Calendar {
  min-width: 300px;
  font-size: 1.3rem;
  font-family: "Proxima Soft", sans-serif;
  border: 0;

  &.editPostingCalendar {
    padding: 16px;

    &.Chabber_Calendar .react-datepicker__navigation {
      top: 16px;
    }
  }

  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__header {
    background: #fff;
    border-bottom: 0;
    padding-top: 2px;
  }
  .react-datepicker__navigation {
    outline: none;
    border: none;
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    top: 0px;
    height: 36px;
    padding: 0;
    z-index: 1;
    width: 36px;
    font-size: 0;
  }
  .react-datepicker__navigation--previous {
    .react-datepicker__navigation-icon--previous {
      display: none;
    }

    &:hover {
      background: $border;
      border-radius: 50%;
    }
    &:before {
      content: "\f053";
      font-weight: 900;
      font-family: "Font Awesome 5 Free";
      position: absolute;
      top: 10px;
      left: 12px;
      font-size: 1.3rem;
    }
  }
  .react-datepicker__navigation--next {
    .react-datepicker__navigation-icon--next {
      display: none;
    }

    &:hover {
      background: $border;
      border-radius: 50%;
    }
    &:before {
      content: "\f054";
      font-weight: 900;
      font-family: "Font Awesome 5 Free";
      position: absolute;
      top: 10px;
      right: 12px;
      font-size: 1.3rem;
    }
  }
  .react-datepicker__current-month {
    font-size: 1.6rem;
    font-weight: $regular;
    line-height: 1.5;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .react-datepicker__day-names {
    margin-bottom: 16px;
    margin-top: 20px;
  }
  .react-datepicker__month {
    margin: 12px 0 0;
  }
  .react-datepicker__day-name {
    color: $mediumGrey;
    margin: 0px;
    width: calc(100% / 7);
  }
  .react-datepicker__week {
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .react-datepicker__day {
    margin: 0px;
    color: $primary;
    font-size: 1.3rem;
    line-height: 37px;
    width: 36px;
    border-radius: 50%;
    font-weight: $bold;

    &.react-datepicker__day--outside-month {
      visibility: hidden;
    }
    &.react-datepicker__day--disabled {
      opacity: 0.5;
      color: $primary;
      font-weight: $regular;
    }
    &.react-datepicker__day--today {
      color: $primary;
    }
    &.react-datepicker__day--selected {
      background: $brand;
      color: #fff;
      font-weight: $bold;
    }
  }
}
