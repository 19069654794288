@import './src/variables.scss';

.courseView{
    padding-top: 24px;

    p{
        font-size: 1.6rem;
        line-height: 24px;
        margin-bottom: 16px;
    }
    strong{
        font-weight: 700;
    }

    ol li, ul li{
        padding: 8px 0;
    }

    blockquote{
        text-align: center;
        font-style: italic;
        position: relative;
        padding: 32px 16px;
        margin-top: 30px;

        &::before{
            content: "";
            background: $brand;
            height: 2px;           
            display: inline-block;
            position: absolute;
            left: 10%;
            right: 10%;
            top: 0;
        }
        &::after{
            content: '"';
            background: #fff;
            color: #f79747;
            position: absolute;
            font-size: 7rem;        
            margin: 0 auto;
            text-align: center;
            top: -3px;
            margin-left: -29px;
            line-height: 0;
            padding: 17px;
        }

    }
    .courseWrapper{
        max-width: 850px;
        margin: 0 auto;   
    }
    .courseCoverImage{
        height: 50vh;
        background: $light;
        border-radius: 0;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .courseContent{
        padding: 40px 80px;
    }
    .courseHeader{
        margin-bottom: 30px;
    }
    .courseText{    
        font-size: 1.6rem;
        margin-bottom: 80px;
    }
    .authorImage{
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
    }
    .nameAndDate{
        vertical-align: middle;
        display: inline-block;
        p{
            margin: 0;
        }
        h6{
            color: $lightGrey;
            text-transform: uppercase;
            margin: 0;
        }
        .alreadyRegistered{
            color: $success;
            font-weight: $bold;
        }
    }
}