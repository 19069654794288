@import '../../../../variables.scss';
.checkoutView{
  table{
      box-shadow: none;
      text-align: right;
  }
  thead{
      font-weight: $bold;

      td:first-of-type{
          width: 65%;
      }
  }
  tr:hover{
      background: initial;
      cursor: default;
  }
  tr:nth-child(even):hover {
    background-color: $light;
  }
  tr td:last-of-type{
     
      text-align: right;
      padding-right: 8px;
  }
  tr.tr_voucher{
      td:last-of-type{
          color: $success;  
          font-weight: $bold;      
      }
  }
  td{
      padding: 8px 0;
  }
tbody.checkoutTotal{
    border-top: 1px solid $lightGrey;
    tr td:first-of-type{
        font-weight: $bold;
    }
    tr td:last-of-type{
        border-bottom: 4px double $primary;
        padding-top: 16px;
        font-weight: $bold;

    }
}
tbody.checkoutMiddle{
    border-top: 1px solid $lightGrey;
    tr td:first-of-type{
        font-weight: $bold;
    }
    tr td:last-of-type{
        border-bottom: 4px $primary;
        padding-top: 16px;
        font-weight: $bold;

    }
}
}