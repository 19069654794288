@import '../../../../variables.scss';

.CountryAndLanguageView{
   .countryAndLanguage_backdrop{
     position: fixed;
     top: 0;
     bottom: 0;
     width: 100%;
     background: rgba(255,255,255,0.95);
     z-index: 2;
     overflow: auto;
   }
   .countryAndLanguage_content{
        text-align: left;
        max-width: 700px;
        margin: 64px auto 32px;

        @media screen and (max-width: $sm) {
          padding: 0 16px;
           }
   }
   .countryAndLanguage_buttonWrapper{
       margin: 8px 16px;

       @media screen and (max-width: $sm) {
       margin: 8px 0;
       width: 100%;
      }
   }
   .countryAndLanguage_block{
    width: 260px;   
    border-radius: 4px;
    background: #fff;
    border: 2px solid $border;  
    text-align: left;
    padding: 16px;    
    transition: border 0.2s ease-in-out;


    &:hover{
        box-shadow: $dropShadowLarge;
        background: $light;
        border: 2px solid $placeholder;  
        cursor: pointer;
    }
    &:active{
        border: 2px solid $brand;
    }
    @media screen and (max-width: $sm) {
        width: 100%;    
       }
   }
   .countryAndLanguage_flag{
       width: 28px;
       height: 28px; 
       display: inline-block; 
       vertical-align: middle;   
       margin-right: 16px; 
       
       img{
           width: 100%;
       }
   }
   .countryAndLanguage_name{  
    font-size: 1.6rem;  
    display: inline-block;
    vertical-align: middle;  
    font-family: 'Proxima-Soft';
    color: $primary;
   }
}