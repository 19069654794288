.preview-container {
  display: flex;
  justify-content: center;
}

.photo-actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  .photo-actions_btn {
    margin-left: 8px;
  }
}
