@import "../../../../../variables.scss";
.carousel {
  .slideInner___2mfX9 {
    position: relative !important;
  }
  .slide___3-Nqo {
    padding-bottom: 0 !important;
    height: auto !important;
  }
  .carousel__slide-focus-ring{
    outline: 0;
  }
  .videoCase {
    padding: 60px 40px;

    .caseTitle {
      font-family: "Merriweather";
      font-weight: bold;
      font-size: 30px;
      text-align: left;
      color: $primary;
      margin-bottom: 32px;
    }
    .caseContent {
      font-size: 1.6rem;
      line-height: 32px;
      text-align: left;
      color: $primary;
    }
    
  }
  .videoPoster {
    background: $border;
    height: 100%;
    position: relative;
    min-height: 300px;

    &.caseOne {
      background: url(../../../../../assets/img/photos/case1.jpg) top center
        no-repeat;
      background-size: cover;
    }
    &.caseTwo {
      background: url(../../../../../assets/img/photos/case2.jpg) top center
        no-repeat;
      background-size: cover;
    }
    &.caseThree {
      background: url(../../../../../assets/img/photos/case3.jpg) top center
        no-repeat;
      background-size: cover;
    }
    &:hover{
      cursor: pointer;

      .videoPoster_playIcon{
        opacity: 1;
        transform: scale(1.05);
      }
    }
    .videoPoster_playIcon {
      color: #fff;
      opacity: 0.8;
      position: absolute;
      top: calc(50% - 32px); //half the icon height of 65px
      left: calc(50% - 32px); //half the icon width of 65px
      transition: 0.2s ease-out;
      cursor: pointer;
      text-shadow: $dropShadowSmall;

      &:hover {
        opacity: 1;
        transform: scale(1.05);
      }
    }
  }
  .carouselDots {
    text-align: center;
    margin-top: 16px;

    button{
        padding: 0;
        width: 10px;
        height: 10px;
        background: $border;
        margin-right: 12px;
        border-radius: 50%;
        border: 0;

        &:hover{
          background: darken($border, 5%);
        }
        &.carousel__dot--selected{
            background: $brand;

        &:hover{
          background: darken($brand, 5%);
        }
        }
        &:focus{
          outline: 0;
        }
    }
  }
}
.videoCase{
  .cardCTA {
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-top: 24px;
    display: inline-block;
    font-weight: $semibold;
  
    span {
      margin-left: 12px;
      transition: 0.2s all ease-in-out;
    }
    &:hover {
      cursor: pointer;     
      span {
        margin-left: 16px;
      }
    }
  }
}

