@import '../../../../variables.scss';
.Header {
    background: $purewhite;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: 0 2px 10px rgba($darkBackground, 0.05);
    padding: 18px 0;
  
    a.active button:before {
      content: "";
      width: 50px;
      height: 2px;
      @include horizontal-gradient($gradientStart, $gradientEnd);
      position: absolute;
      bottom: 0;
      border-radius: 2px;
    }
    .headerLogo {
      width: 160px;
    }
    button:hover {     
      opacity: 0.8;
    }
  }

.mainHeader{
    button#countryAndLanguageButton span{
        font-weight: $regular;
    }
}
.MuiDrawer-paper.mobileHeaderMenuPaper{
    @include horizontal-gradient($gradientStart, $gradientEnd);
    left: 0px;   

    .mobileHeaderMenu{
        padding: 12px 16px;   
        text-align: center;
    }
    .mobileHeaderMenu_content{
        text-align: center;

        a.active button:before{
            content: "";
            width: 50px;
            height: 3px;
            background-color: #fff;           
            position: absolute;
            bottom: 4px;
            border-radius: 2px;
        }
        button{
            font-size: 2rem;
            color: #fff;

            &.mobileHeaderMenu_CTA{
                background: #fff;
                color: $brand;
                padding: 8px 24px;
            }
        }
    }
    .mobileHeaderMenu_contentGroup{
        margin: 24px 0;

        &:nth-child(2){
            margin-top: 100px;
        }
    }
    .mobileHeaderMenu_countryAndLanguage{
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 16px;
        font-size: 1.6rem;
        color: #fff;

        img{
            margin-right: 8px;
            width: 20px;
            vertical-align: middle;
        }
    }
}