@import "../../../variables.scss";
.Frontpage {

  .hero {
    height: calc(800px - 187px);
    position: relative;
    padding-top: 100px;

    @media screen and (max-width: $md) {
      height: unset;
      padding-top: 0;
     }

    h1.tagline {
      color: $gradientEnd;
      font-family: "Merriweather";
      font-weight: bold;
      font-size: 4.7rem;
      text-align: left;
      line-height: normal;
      width: 480px;
      margin-bottom: 12px;
      max-width: 100%;

      @media screen and (max-width: $sm) {
        font-size: 3.7rem;
       }
    }
    .heroContent {
      width: 369px;
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      text-align: left;
      color: $mediumGrey;
      display: inline-block;
    }
    .heroImage {
      position: absolute;
      top: -17%;
      right: -20%;
      z-index: -1;
      width: 75%;
      max-width: 1100px;
    }
    .heroImage_mobile{
      width: 100%;
  }
  }
  section {
    padding: 100px 0 50px;
    position: relative;

    &.sectionWithWave {
      padding-top: 30px;
    }
  }
  .sectionHeading {
    font-family: "Merriweather", serif;
    font-weight: bold;
    font-size: 3rem;
    line-height: 38pt;
    text-align: center;
    margin: 30px 0 80px;
  }
  .sectionParagraph {
    font-size: 1.6rem;
    line-height: 22pt;
    text-align: center;
    max-width: 480px;
    margin: 0 auto;
  }
  .section_light {
    .sectionHeading {
      color: $darkBackground;
    }
    .sectionParagraph {
      color: $primary;
    }
  }
  .section_dark {
    background: $darkBackground;
    color: $purewhite;
    .sectionHeading {
      color: $purewhite;
    }
    .sectionParagraph {
      color: $purewhite;
    }
  }
  .segmentCard {
    box-shadow: $dropShadowLarge;
    height: 100%;
    transition: 0.2s all ease-in-out;
    background-color: $purewhite;

    &:hover {
      transform: translateY(-2px);
      cursor: pointer;
      box-shadow: $dropShadowMedium;
    }
  }
  .cardImage {
    height: 185px;
    background: $border;
    background-size: cover;

    &.cardImage_events {
      background-image: url("../../../assets/img/photos/event.jpg");
    }
    &.cardImage_kitchen {
      background-image: url("../../../assets/img/photos/kitchen.jpg");
    }
    &.cardImage_private {
      background-image: url("../../../assets/img/photos/private.jpg");
    }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
      &.cardImage_events {
        background-image: url("../../../assets/img/photos/event@2x.jpg");
      }
      &.cardImage_kitchen {
        background-image: url("../../../assets/img/photos/kitchen@2x.jpg");
      }
      &.cardImage_private {
        background-image: url("../../../assets/img/photos/private@2x.jpg");
      }
    }
  }
  .cardTitle {
    font-family: "Merriweather", serif;
    color: $primary;
    font-size: 2.8rem;
  }
  .cardContent {
    font-size: 1.6rem;
    color: $primary;
    line-height: 22pt;
    margin-top: 12px;
  }
  .cardCTA {
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-top: 24px;
    display: inline-block;
    font-weight: $semibold;

    span {
      margin-left: 12px;
      transition: 0.2s all ease-in-out;
    }
    &:hover {
      cursor: pointer;     
      span {
        margin-left: 16px;
      }
    }
  }

  .topWave {
    margin-bottom: -10px;
    position: relative;
    left: -1%;
    width: 102%;
  }
  .blob {
    position: absolute;
    z-index: -1;

    &.blobVideo {
      left: -40%;
    }
    &.blobFeatures {
      right: -30%;
    }
    &.blobSegments {
      left: -15%;
      top: -20%;
    }
  }
}
