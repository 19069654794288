.custom-supplement-form {
    .interval-type {
        width: 100%;
    }

    .submit-contrainer {
        padding: 8px 0;
        display: flex;
        justify-content: flex-end;
    }
}