@import '../../../../../variables.scss';
.freelancerProfile{
    padding: 32px;
    max-width: 940px;
    border-radius: 4px;
    font-size: 1.4rem;
    margin: 0px auto;
    // box-shadow: $dropShadowMedium;

    .freelancerProfile_top{
        border-bottom: 1px solid $border;
        padding-bottom: 32px;
        position: relative;
    }
    .FreelancerProfile_actionButtons{
        position: absolute;
        top: 0;
        right: 0;
    }
    .freelancerProfile_image{
        width: 150px;
        height: 150px;
        background: $border;
        border-radius: 12px;
        display: inline-block;
        vertical-align: top;
        margin-right: 24px;
       
    }
    .freelancerProfile_info{
        display: inline-block;
    }
    .freelancerProfile_name{
        margin-top: 0;
        margin-bottom: 0;
        font-size: 2.6rem;
        font-weight: $bold;
    }
    .favouriteIndicator{
        color: $favourite;
        margin-left: 8px;
    }
    .freelancerProfile_rating{
        margin-bottom: 12px;
        color: $warning;
        font-size: 1.6rem;
        display: inline-block;
    }
    .freelancerProfile_badgeWrapper{
        margin-top: 22px;
    }
    .freelancerProfile_badge{
        display: inline-block;
        padding: 8px;
        font-weight: $bold;
        background: $placeholder;
        border-radius: 2px;
        margin-right: 8px;        
        
        &:last-child{
            margin-right: 0;
        }
        &.bestMatch{
            background: rgba($brand,0.25);
            color: $brand;
        }
        &.previouslyHired{
            background: rgba($success,0.25);
            color: $success;
        }
        &.nonNativeSpeaker{
            background: rgba($info,0.25);
            color: $info;
        }
        &.favouriteHired{
            background: rgba($favourite,0.25);
            color: $favourite;
        }
    }
    .freelancerProfile_resumeSection{
        margin: 24px 0;
    }
    .freelancerProfile_resumeSectionTitle{
        color: $brand;
        font-size: 1.8rem;
    }
    .freelancerProfile_showMoreLink{
        font-size: 1.3rem;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 12px;
    }
    .freelancerProfile_loadMoreLink{
        font-size: 1.3rem;       
        cursor: pointer;
        margin-bottom: 12px;
        color: $placeholder;
    }
    .freelancerProfile_resumeSectionContent{
        margin-left: 55px;
        border-bottom: 1px solid $border;
        padding-bottom: 16px;
        font-size: 1.6rem;
        margin-top: 16px;
        white-space: pre-line;

        &:last-child{
            border-bottom: 0;
            padding-bottom: 0;
        }
        .resumeSection_title{
            font-weight: $semibold;
            margin-bottom: 0px;
        }
        .resumeSection_date{
            font-size: 1.1rem;
            color: $placeholder;
        }
        p{
            font-size: 1.6rem;
            color: $primary;
            margin-top: 4px;
        }
        &.emptyContent{
            color: $lightGrey;
            user-select: none;
        }
    }
    .freelancerProfile_extraDetails{
        padding: 6px 0;
        border-bottom: 0.55px solid $border;

        .freelancerProfile_companyLogo{
            width: 45px;
            height: 45px;
            display: inline-block;
            background-color: $border;
            border-radius: 6px;
            vertical-align: middle;
            background-size: cover;
            overflow: hidden;
        }
        .extraDetails_info{
            display: inline-block;
            margin-left: 12px;
            vertical-align: middle;
        }
        .extraDetails_tilte{
            font-size: 1.6rem;
            font-weight: $semibold;
        }
        .extraDetails_description{
            font-size: 1.6rem;
            color: #495057;
            margin: 0;
        }
    }

    p.competence_has{
        color: $primary;
    }
    .competence_has:before{
        content:"\f00c ";
        color: $success;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-right: 8px;
    }    
    .competence_hasNot{
        color: $placeholder;
        filter: blur(0.5px);
    }
    .competence_hasNot:before{
        content:"\f068 ";
        color: $placeholder;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-right: 8px;
    }

}