@import '../../../../variables.scss';

.loginPaper{
    background: $purewhite;
    position: relative;
    display: inline-block;
    margin: 40px 16px;
    border-radius: 6px;
    box-shadow: $dropShadowLarge;
    max-width: 460px;
}
.backgroundWhite{
    background: #fff;
}
.backgroundGradient{
    @include horizontal-gradient($gradientStart, $gradientEnd);
}
#facebookLogin{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}