@import './src/variables.scss';


.adminMenu {
  width: 250px;
  background: $darkBackground;
  color: $purewhite;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0px;
  display: inline-block;
  user-select: none;
  transition: all 0.2s ease-in-out;

  /* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1); 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3); 
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4); 
}
}
.adminMenu_wrapper{
  position: absolute;
  display: inline-block;
  width: 250px;
  top: 77px;
  bottom: 48px;
  vertical-align: top;
  overflow: hidden;

  &:hover{
    overflow: auto;
  }
}
.adminMenu_content {
  padding: 20px;
}
.adminMenu_top {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 0 20px;
  border-bottom: 1px solid $purewhite;
}
.adminLogo {
  font-weight: bold;
  font-size: 2.2rem;
  letter-spacing: 0.1em;
  line-height: 34px;
}
.adminTagline {
  font-weight: normal;
  font-size: 0.7rem;
  letter-spacing: 0.3em;
  line-height: 12px;
  text-transform: uppercase;
}
.adminMenu_linkGroup{
  margin: 14px 0;
}
.adminMenu_linkGroupTitle{
  font-weight: bold;
  font-size: 1.1rem;
  color: $lightGrey;
  text-transform: uppercase;
  margin-bottom:8px;
  }
.adminMenu_link{
  padding: 11px 19px;
  display: block;
  outline: none;

  &:hover{
    color: darken($purewhite, 20%);
  }
  &.active{
    background: rgba(255,255,255,0.1);
    border-radius: 4px;
  }
}
.adminMenu_link-icon{
  display: inline-block;
  font-size: 1.2rem;
}
.adminMenu_link-label{
  display: inline-block;
  margin-left: 16px;
  font-size: 1.3rem;
}
.adminMenu_bottom{
  border-top: 1px solid $purewhite;
  margin: 0 20px;
  position: absolute;
  right: 0;
  bottom:0;
  left: 0;
  background: $darkBackground;
}
.adminMenu_Atlaslink{
  padding: 16px 19px;
  display: block;
  cursor: pointer;
}
.adminMenu_AtlasLabel{
  display: inline-block;
  font-size: 1.3rem;
}
.adminMenu_AtlasIcon{
  float: right;
  font-size: 1.4rem;
}