@import '../../../variables.scss';
.AdminPageHeader{
    padding-top: 40px;
    margin-bottom: 30px;
}
.AdminPageAvatar{
    width: 80px;
    height: 80px;
    border-radius: $imageRadius;
    background: $lightGrey;
    border: 2px solid $purewhite;
    box-shadow: $dropShadowSmall;
    display: inline-block;
    vertical-align: middle;
    margin-right: 18px;
}
.AdminPageAvatar.rounded{
    width: 80px;
    height: 80px;
    border-radius: 12px;
    background: $lightGrey;
    border: 2px solid $purewhite;
    box-shadow: $dropShadowSmall;
    display: inline-block;
    vertical-align: middle;
    margin-right: 18px;
}
.AdminPageTitle{
    display: inline-block;
    vertical-align: middle;
    display: inline-block;
    font-size: 2.2rem;
    color: $primary;
    font-weight: 700;
    line-height: 3.4rem;

    i{
        margin-left: 12px;
        font-size: 1.6rem;
    }
}
.AdminPageSubtitle{
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 22px;
    text-align: left;
}
.AdminPageHeader_options{
    float: right;
}
.adminTable{
    margin: 20px 0;
}
