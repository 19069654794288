@import '../../../../../variables.scss';
.pricingView{
    table{
        box-shadow: none;
        margin-bottom: 0;
    }
    tr{
        cursor: default;
    }
    tr:hover{
        background: initial;
    }
    tr:nth-child(even):hover {
        background-color: $light;
      }
    tr td{
        border-bottom: 0;
    }
    tr th{
        font-weight: $bold;
        color: $primary;
    }
}