@import "../../../../../variables.scss";
.aboutUsView {
  .divider {
    margin-top: 20px;
  }

  .hero {
    padding-top: 20px;

    h1.tagline {
      color: $darkBackground;
      font-family: "Merriweather";
      font-weight: bold;
      font-size: 4.7rem;
      text-align: left;
      line-height: normal;
      width: 480px;
      margin-bottom: 12px;
      margin-top: 0;
    }
    .heroContent {
      max-width: 450px;
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      text-align: left;
      color: $mediumGrey;
    }
    .heroImage {
      width: 100%;
      border-radius: 12px;
    }
  }
  section {
    padding: 64px 0;
  }
  .infoParagraph {
    max-width: 600px;
  }
  .contactDetail {
    font-weight: $bold;
    font-size: 2.2rem;
    line-height: 34px;
    text-align: center;
    margin-top: 16px;
  }
  .team_countrySelect {
    padding: 6px 8px;
    cursor: pointer;

    &.active {
      font-weight: $bold;

      &:after {
        content: "";
        width: 100%;
        height: 2px;
        @include horizontal-gradient($gradientStart, $gradientEnd);
        display: block;
        border-radius: 1px;
      }
    }
  }
  .teamPhoto {
    height: 200px;
    width: 200px;
    @include horizontal-gradient($gradientStart, $gradientEnd);
    background-size: contain;
    background-repeat: no-repeat;

    @media screen and (max-width: $sm) {
      height: 150px;
      width: 150px;
    }

    &.stardust {
      background: url(../../../../../assets/img/photos/stardust.jpg);
      background-size: cover;
      background-position-y: center;
    }
  }
}
.whistleblower-section,
.esg-section,
.contact-section {
  .paragraphs-container {
    justify-content: center;
    p {
      font-size: 1.6rem;
      line-height: 28px;
      color: #6b7584;
    }
  }
}
