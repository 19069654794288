@import "../../../../../variables.scss";

.companyFeedbackView {
  .masterSliderWrapper {
    .MuiSlider-root {
      color: $darkBackground;
    }
    .MuiSlider-markActive{
      background-color: transparent;
    }
  }
  .MuiSlider-root.undertime{
    color: $danger;
  }
  .MuiSlider-root.overtime{
    color: $success;
  }
  .feedbackItem {
    img {
      border-radius: 6px;
      margin-right: 8px;
      vertical-align: top;
      width: 45px;
      height: 45px;
    }
    .feedbackName {
      display: inline-block;
      margin: 0 8px 0 0;
      vertical-align: middle;
    }
    button.upvoted {
      color: $success;

      i {
        font-weight: 900;
      }
    }
    button.downvoted {
      color: $danger;

      i {
        font-weight: 900;
      }
    }
    button.invitedToBeFavorite {
      color: $placeholder;

      i {
        font-weight: 900;
      }
    }
    button.confirmedFavorite {
      color: $favourite;

      i {
        font-weight: 900;
      }
    }
    &.noShow{
      opacity: 0.3;
      filter: grayscale(1);
    }
    .ratingStars{
      vertical-align: middle;
      margin-right: 12px;
    }
  }
}
