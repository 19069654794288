@import '../../../../../variables.scss';

.adminTable{
    tr.eventMissing{
        background: rgba($danger,0.2);

        &:hover{
            background: rgba($danger,0.3);
        }
    }
}