@import '../../../../variables.scss';

.signupView{
    .registerFreelancer{
        @include horizontal-gradient($gradientStart, $gradientEnd);
        color: white;
        .title{
            font-family: Merriweather;
            font-weight: bold;
            font-size: 47px;
            text-align: center;
            color: #fff;       
            margin-bottom: 32px;     
        }
        p{
            max-width: 343px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #fff;
            margin: 0 auto  ;
        }
        button{
            background-color: #fff;
            color: $brand;
            padding: 12px 24px;
            min-width: 260px;
            margin: 12px;  
            transition: all 0.2s ease-in-out; 
            &:hover{
                transform: translateY(-2px);
                box-shadow: $dropShadowSmall;
            }
        }
    }
    .registerCompany{      
        color: $primary;

        .title{
            font-family: Merriweather;
            font-weight: bold;
            font-size: 47px;
            text-align: center;    
            margin-bottom: 32px;  
            color: $darkBackground;   
        }
        p{
            max-width: 343px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            margin: 0 auto  ;
        }
        button{
            background-color: $darkBackground;
            color: white;
            padding: 12px 24px;
            min-width: 260px;
            margin: 12px; 
            transition: all 0.2s ease-in-out; 
            box-shadow: none;
            &:hover{
                transform: translateY(-2px);
                box-shadow: $dropShadowSmall;
            }   
            
            &.privatSignup{
                background-color: $placeholder;
            }
        }
    }
}