@import '../../../../../variables.scss';
.featureItem {
    max-width: 370px;

    .featureTitle {
      font-family: "Merriweather", serif;
      color: $darkBackground;
      font-size: 2.2rem;
    }
    .featureContent {
      font-size: 1.6rem;
      color: $primary;
      line-height: 22pt;
      margin-top: 12px;
    }
  }