@import '../../../../../variables.scss';

.courseCardView{
    height: 100%;
    .courseCard{
        cursor: pointer;
        overflow: hidden;
    }
    .courseCard_coverImage{
        height: 150px;
        background: $border;
        background-size: cover;
        background-position: center center;
    }
    .courseCard_content{
     padding: 12px;  
    }
    .courseCard_title{
        margin-top: 0;
    }
    .courseCard_dates{
        color: $brand;
        text-transform: uppercase;
        font-weight: $bold;
        font-size: 1.1rem;

        &.noDates{
            color: $placeholder;
        }
        &.registered{
            color: $success;
        }
    }
}