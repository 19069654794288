@import '../../../../../variables.scss';
@font-face {
    font-family: 'Reman';
    src: url('/fonts/Reman.ttf')  format('truetype');
}
.freelancerContract{
  
    .contractWrapper{
        max-width: 800px;
        box-shadow: $dropShadowMedium;
        padding: 20px;
        margin: 32px auto;
        border-radius: 4px;
    }
    table{
        box-shadow: none !important;
        width: 100% !important;
        border-radius: 0;
        
        th{
            text-align: left;
        }
        tr:hover{
            background: $purewhite;
            cursor: default;
        }
        tr:nth-child(even):hover{
            background: $light;
            cursor: default;
        }
        td{
            border: none !important;
            padding: 2px 10px !important;

            span{
                background: transparent !important;
            }
            strong{
            font-family: "Proxima Soft" !important;
            }
           
        }

    }
    .signatureSection{       
        margin-bottom: 48px;
        margin-top: 24px;          
        p{
           color: $placeholder;
           margin-top: 0;
        }
        .companySignature{
            width: 90px;
            margin-top: -28px; 
        }
        .freelancerSignature{
            font-family: 'Reman';
            font-size: 5.2rem;
        }
        .signature{
            border-bottom: 1px solid $lightGrey;     
            height: 46px;           
        }
    }
}