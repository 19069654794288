@import '../../../variables.scss';

.dashboard{
   .cardNumber{
       font-size: 2.6rem;
       font-weight: 700;
       text-align: right;
   }
   .cardNumber_secondary{
       text-align: right;
       font-size: 1.3rem;
       color: $lightGrey;
       font-weight: 600;
   }
}
