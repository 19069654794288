@import '../../../../variables.scss';

.chatView{        
min-width: 500px;

@media screen and (max-width: $sm) {
    min-width: 100vw;
   }
          /* width */
          ::-webkit-scrollbar {
            width: 5px;
          }
          /* Track */
          ::-webkit-scrollbar-track {
            background: $border; 
          }
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: $placeholder; 
            border-radius: 10px;
          }
    .chatLoader_icon {
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        position: absolute;
        }
    .chatTop{
        background: #fff;
    }
    .chatWrapper{
        position: absolute;
        display: inline-block;
        top: 130px;
        bottom: 80px;
        vertical-align: top;
        overflow: auto;
        width: 100%;

    }
    .chatContent{    
        padding: 0 24px 24px;
    }
    .chatBottom{
        border-top: 1px solid $border;        
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fff;

        .chatBottom_content{
            padding: 24px;

            .chatIcon{
                padding-top: 12px;
            }
        }
    }
    .dayIndicator{           
        text-align: center;
        margin: 20px auto;

        span{           
            color: $placeholder;           
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: $semibold;
            position: relative;
            padding: 0 20px;

            &:before{
                content: "";
                height: 1px;
                background: $border;
                top: 50%;
                width: 120px;
                position: absolute;
                left: -120px;
            }
            &:after{
                content: "";
                height: 1px;
                background: $border;
                top: 50%;
                width: 120px;
                position: absolute;
                right: -120px;
            }
        }

    }
    .chatMessage_row{         
        margin-bottom: 16px;
        box-sizing: border-box; 

        &.messagePending{             
            opacity: 0.4;
            animation-name: fadeInUp;
            -webkit-animation-name: fadeInUp;     
            animation-duration: 0.4s;
            animation-fill-mode: both;
            -webkit-animation-duration: 0.4s;
            -webkit-animation-fill-mode: both
        }
        @keyframes fadeInUp {
            from {
                transform: translate3d(0,10px,0)
            }
        
            to {
                transform: translate3d(0,0,0);
                opacity: 0.4;
            }
        }
        
        @-webkit-keyframes fadeInUp {
            from {
                transform: translate3d(0,10px,0)
            }
        
            to {
                transform: translate3d(0,0px,0);
                opacity: 0.4;
            }
        }          
        .message_sent{
            text-align: right;
            position: relative;

            .messageBody{
                border-radius: 12px 12px 0 12px;               
                @include horizontal-gradient($gradientStart, $gradientEnd);
                color: #fff;
                text-align: left;
                margin-right: 45px;
            }
            .image{
                width: 35px;
                height: 35px;
                background: $border;
                border-radius: 50%;
                background-size: contain;
                position: absolute;
                bottom: calc(37px/2);
                right: 0;
            }
            .timestamp{
                font-size: 1rem;
                color: $placeholder;
                margin-right: 5px;
            }
        }
        .message_received{
            text-align: left;
            position: relative;

            .messageBody{
                border-radius: 12px 12px 12px 0;
                background: $border;
                margin-left: 45px;              

            }
            .image{
                width: 35px;
                height: 35px;
                background: $border;
                border-radius: 50%;
                background-size: contain;
                position: absolute;
                bottom: calc(37px/2);                
            }
            .timestamp{
                font-size: 1rem;
                color: $placeholder;
                margin-left: 5px;
            }
        }
    }
    .messageBody{              
        padding: 16px;
        display: inline-block;
        max-width: 60%;
    }
    .chatNetworkError p{
        max-width: 300px;
        margin: auto;
    }
}