@import "./variables.scss";

html {
  font-family: "proxima-soft", sans-serif;
  font-size: 62.5%;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-weight: $regular;
  font-size: 1.3rem;
  font-style: normal;
  color: $primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: $gradientEnd;
}

::selection {
  color: #fff;
  background: $gradientEnd;
}
.wrapper {
  margin-left: 250px;  
  @media screen and (max-width: $sm) {
    margin-left: 0;
  }
  &.adminWrapper{
    transition: all 0.2s ease-in-out;
  }
}
.wrapper.atlasActive {
  margin-left: 320px;

  .adminMenu {
    left: 70px;
  }
  .atlasMenu {
    left: 0;
  }
}
.bodyContent {
  margin: 0 45px;
  @media screen and (max-width: $sm) {
    margin: 0 16px;
    margin-bottom: 80px;
  }
  margin-bottom: 16px;
}

//EMPTYSTATE
.emptyState_wrapper{
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-left: $mainNavigation;

  &.fullView{
    margin-left: 0;
  }
  @media screen and (max-width: $sm) {
    margin-left: 0;
  }

  .emptyState_content{
    pointer-events: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

//LAYOUT
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.center {
  margin: 0 auto;
}
.text-center {
  text-align: center;
  margin: 0 auto;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.maxW-600 {
  max-width: 600px;
}
.maxW-800 {
  max-width: 800px;
}
.vh-100 {
  min-height: calc(100vh - 72px); //Header height
}
.relative {
  position: relative;
}
.hidden {
  display: none !important;
}
.editLocked {
  pointer-events: none;
  cursor: default;
  user-select: none;
}
//DIVIDERS
hr.divider-20 {
  margin: 20px 0;
}
hr.divider-40 {
  margin: 40px 0;
}

//HELPERS
.no-margin-top {
  margin-top: 0;
}
.no-margin-right {
  margin-right: 0;
}
.no-margin-bottom {
  margin-bottom: 0;
}
.no-margin-left {
  margin-left: 0;
}
.margin-0 {
  margin: 0px;
}
.margin-8 {
  margin: 8px;
}
.margin-16 {
  margin: 16px;
}
.margin-24 {
  margin: 24px;
}
.margin-32 {
  margin: 32px;
}
.margin-40 {
  margin: 40px;
}
.margin-v-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.margin-v-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.margin-v-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.margin-v-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.margin-v-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.margin-v-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.margin-h-0 {
  margin-left: 0;
  margin-right: 0;
}
.margin-h-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.margin-h-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.margin-h-24 {
  margin-left: 24px;
  margin-right: 24px;
}
.margin-h-32 {
  margin-left: 32px;
  margin-right: 32px;
}
.margin-h-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.margin-top-0 {
  margin-top: 0;
}
.margin-top-8 {
  margin-top: 8px;
}
.margin-top-16 {
  margin-top: 16px;
}
.margin-top-24 {
  margin-top: 24px;
}
.margin-top-32 {
  margin-top: 32px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-8 {
  margin-bottom: 8px;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}
.margin-bottom-24 {
  margin-bottom: 24px;
}
.margin-bottom-32 {
  margin-bottom: 32px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-right-0 {
  margin-right: 0;
}
.margin-right-8 {
  margin-right: 8px;
}
.margin-right-16 {
  margin-right: 16px;
}
.margin-right-24 {
  margin-right: 24px;
}
.margin-right-32 {
  margin-right: 32px;
}
.margin-right-40 {
  margin-right: 40px;
}
.margin-left-0 {
  margin-left: 0;
}
.margin-left-8 {
  margin-left: 8px;
}
.margin-left-16 {
  margin-left: 16px;
}
.margin-left-24 {
  margin-left: 24px;
}
.margin-left-32 {
  margin-left: 32px;
}
.margin-left-40 {
  margin-left: 40px;
}
.no-padding-top {
  padding-top: 0;
}
.no-padding-right {
  padding-right: 0;
}
.no-padding-bottom {
  padding-bottom: 0;
}
.no-padding-left {
  padding-left: 0;
}
.padding-0 {
  padding: 0px;
}
.padding-8 {
  padding: 8px;
}
.padding-16 {
  padding: 16px;
}
.padding-24 {
  padding: 24px;
}
.padding-32 {
  padding: 32px;
}
.padding-40 {
  padding: 40px;
}
.padding-v-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.padding-v-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.padding-v-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.padding-v-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.padding-v-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.padding-v-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.padding-h-0 {
  padding-left: 0;
  padding-right: 0;
}
.padding-h-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.padding-h-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.padding-h-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.padding-h-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.padding-h-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.padding-top-0 {
  padding-top: 0;
}
.padding-top-8 {
  padding-top: 8px;
}
.padding-top-16 {
  padding-top: 16px;
}
.padding-top-24 {
  padding-top: 24px;
}
.padding-top-32 {
  padding-top: 32px;
}
.padding-top-40 {
  padding-top: 40px;
}
.padding-bottom-0 {
  padding-bottom: 0;
}
.padding-bottom-8 {
  padding-bottom: 8px;
}
.padding-bottom-16 {
  padding-bottom: 16px;
}
.padding-bottom-24 {
  padding-bottom: 24px;
}
.padding-bottom-32 {
  padding-bottom: 32px;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}

.radius-0 {
  border-radius: 0;
}
.radius-4 {
  border-radius: 4px;
}
.radius-6 {
  border-radius: 6px;
}
.radius-12 {
  border-radius: 12px;
}

.cursorPointer {
  cursor: pointer;
}
//PAGETYPES
.fullHeightWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .content {
    flex: 1 0 auto;
  }
  .Footer {
    flex-shrink: 0;
  }
}
//APPEARANCE
.paper {
  background: $purewhite;
  box-shadow: $dropShadowMedium;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}
.borderBox {
  height: 100%;
  position: relative;
  border: 0.55px solid $border;
}
.placeholder {
  color: $placeholder;
}
//IMAGES
.imgCircle {
  border-radius: 50%;
}
.noDrag {
  user-select: none;
  -webkit-user-modify: read-only;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
// FONTS
.displayFont {
  font-family: "Merriweather", serif;
  font-size: 3rem;
  color: $darkBackground;
  margin-bottom: 16px;

  &.light{
    color: #fff;
  }
}
h1 {
  font-size: 2.2rem;
  color: inherit;
  font-weight: $bold;
  line-height: 3.4rem;

  &.large {
    font-size: 4.7rem;
  }
  &.white {
    color: $purewhite;
  }
  &.displayFont {
    font-family: "Merriweather", serif;
    font-size: 3rem;
    color: $darkBackground;
  }
}
h2 {
  font-weight: $bold;
  font-size: 1.6rem;
  color: inherit;

  &.white {
    color: $purewhite;
  }
  &.brand {
    color: $brand;
  }
  &.displayFont {
    font-family: "Merriweather", serif;
    font-size: 2.2rem;
    color: $darkBackground;
  }
}
h6 {
  font-weight: 300;
  font-size: 1rem;
  color: $placeholder;
}
.bold{
  font-weight: $bold;
}
a {
  color: inherit;
  text-decoration: none;
}
a.brand {
  color: $brand;
}
a.bold {
  font-weight: $bold;
}
p {
  font-size: 1.3rem;
}
p.semiBold {
  font-weight: $semibold;
}
p.bold {
  font-weight: bold;
}
p.small {
  font-size: 0.8rem;
}
p.paragraphText {
 
  font-size: 1.6rem;
  line-height: 22px;
  text-align: left;
}
p.paragraphText.italic {
  font-style: italic;
}
p.infoParagraph {
  font-size: 1.6rem;
  line-height: 28px;
  color: $mediumGrey;
  max-width: 450px;
  display: inline-block;
  &.text-center {
    display: block;
  }
  .brand {
    color: $brand;
  }
}
span.danger{
  color: $danger;
}
.menuHeader {
  font-weight: bold;
  font-size: 1.1rem;
  color: $lightGrey;
}
.menuItem {
  font-weight: $semibold;
  font-size: 1.3rem;
  line-height: 16px;
  color: $mediumGrey;
}
.menuFlag {
  margin-right: 8px;
}
.presentationText {
  font-weight: bold;
  font-size: 4.7rem;
  text-align: left;
  color: $primary;
}
//BUTTONS
.button, a.button {
  background: $border;
  color: $mediumGrey;
  display: inline-block;
  border-radius: 4px;
  font-weight: $bold;
  text-align: center;
  box-shadow: $dropShadowButton;
  white-space: nowrap;
  user-select: none;
  font-size: 1.3rem;
  text-transform: none;

  &:hover {
    box-shadow: $dropShadowButtonHover;
  }
}
.button.block {
  display: block;
}
.button a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  display: block;
  padding: 11px 16px;
  text-transform: none;
}
.btn-icon {
  margin-right: 12px;
}

.button-success {
  color: $purewhite;
  background: $success;

  &:hover {
    background: darken($success, 2%);
  }
}
.button-white {
  color: $primary;
  background: $purewhite;

  &:hover {
    background: darken($purewhite, 2%);
  }
}
button.button-gradient, a.button {
  color: $purewhite;
  @include horizontal-gradient($gradientStart, $gradientEnd);

  &:hover {
    @include horizontal-gradient($gradientStart, $gradientEnd);
    opacity: 0.9;
  }
}

button {
  text-transform: none;
  padding: 6px 21px;
}
button span {
  text-transform: none;
  font-weight: $bold;
}
button.icon {
  padding: 6px 14px;
  min-width: unset;
  font-size: 1.3rem;
  line-height: 2.4rem;
}
button.danger {
  background: $danger;
  color: $purewhite;

  &:hover {
    background: darken($danger, 2%);
  }
}
.success button {
  color: $success;
}
button.success {
  background: $success;
  color: $purewhite;
  text-transform: initial;

  &:hover {
    background: darken($success, 2%);
  }
}
button.default {
  color: $primary;
  background: $border;

  &:hover {
    background: darken($border, 2%);
  }
}
button.white {
  color: $primary;
  background: $purewhite;

  &:hover {
    background: darken($purewhite, 2%);
  }
  .btn-icon.active {
    color: $brand;
  }
  &.brandColor{
    color:$brand;
  }
}
.buttonLoader {
  position: absolute;
}
.buttonLoader svg circle {
  stroke: $purewhite;
}
a[role="button"] {
  text-transform: none;

  &.button-gradient {
    color: $purewhite;
    @include horizontal-gradient($gradientStart, $gradientEnd);
    font-weight: $semibold;
  }
}
//ICONS
i.success {
  color: $success;
}
i.danger {
  color: $danger;
}
i.warning {
  color: $warning;
}
i.brand {
  color: $brand;
}
i.info {
  color: $info;
}
i.white {
  color: #fff;
}

//TABLES
table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: $dropShadowLarge;
  border-radius: 6px;
  margin-bottom: 24px;
}

// tbody td:first-child, th:first-child{
//   padding-left: 48px;
// }
td.tableCell {
  border-bottom: 0;
  border-top: 0;
  color: $primary;

  .tableBadge {
    font-weight: $semibold;
    background: $border;
    color: $primary;
    display: inline-block;
    padding: 2px 8px;
    font-size: 1.1rem;
    border-radius: 4px;

    &.success {
      color: $success;
      background: rgba($success, 0.25);
    }
    &.brand {
      color: $brand;
      background: rgba($brand, 0.25);

      &.newMessage {
        float: right;
      }
    }
  }
}
tr:nth-child(even) {
  background-color: $light;
}

.tableHead {
  border-radius: 6px 6px 0 0;
  box-shadow: $dropShadowSmall;

  // .tableRow:hover{
  //     background: $purewhite;
  // }
  .tableRow th {
    font-size: 1.1rem;
    color: $lightGrey;
    text-transform: uppercase;
    font-weight: $bold;
    cursor: default;
  }
  .tableCell_last {
    padding-right: 0;
  }
}
tr.tableRow {
  &.tableRow_hover:hover {
    background-color: darken($light, 1%);
    cursor: pointer;
  }
  &.tableRow_hover{
    .tableCell{
      cursor: pointer;
    }
  }
}
.tableRow:hover .tableOptions {
  opacity: 1;
  color: $lightGrey;
}
.tableOptions {
  color: $lightGrey;
  opacity: 0;
}
.tableOptions i:hover {
  color: darken($lightGrey, 10%);
}
.tableRow.folder {
  background: $border;

  .tableCell {
    font-weight: $bold;
  }
}
.subTable {
  td:first-child {
    padding-left: 60px;
  }
}
td.tableStatus {
  text-align: right;
  padding-right: 14px;
}
th.tableStatus {
  padding-right: 0;
}
.tableRow.folder td:first-child::before {
  content: "\f07c";
  font-family: "Font Awesome 5 Free";
  margin-right: 4px;
  font-weight: $regular;
}
tbody tr.tableRow.added {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
@keyframes fadein {
  from {
    background: rgba($success, 0.5);
  }
  to {
    background: initial;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    background: rgba($success, 0.5);
  }
  to {
    background: initial;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    background: rgba($success, 0.5);
  }
  to {
    background: initial;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    background: rgba($success, 0.5);
  }
  to {
    background: initial;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    background: rgba($success, 0.5);
  }
  to {
    background: initial;
  }
}
//DROPDOWNMENU
.dropdownMenu {
  .dropdownMenu_content {
    border-radius: 12px;
    // max-width: 180px;
  }
  i {
    margin-right: 16px;
  }
  .menuTitle {
    font-weight: 700;
    font-size: 11px;
    padding: 12px 24px;
    text-align: left;
    color: $lightGrey;
    text-transform: uppercase;
    outline: none;
    min-width: 150px;
    user-select: none;
  }
  .menuItem {
    font-weight: 500;
    font-size: 13px;
    text-align: left;
    color: $mediumGrey;
    padding: 8px 24px;

    &:hover {
      background: $light;
    }
    a {
      text-decoration: none;
    }
  }
}

input {
  font-size: 1.3rem;
  border-radius: 6px;
  border: 1px solid $border;
  padding: 10px 20px;
  margin: 8px 0;
  vertical-align: middle;
  outline: none;
  resize: none;

  &::placeholder {
    color: $placeholder;
  }
}
textarea {
  font-size: 1.3rem;
  border: 1px solid $border;
  padding: 10px 20px;
  margin: 8px 0;
  vertical-align: middle;
  outline: none;
  resize: none;
  box-sizing: border-box;

  &::placeholder {
    color: $placeholder;
  }
}
input.block {
  width: 100%;
}
textarea.block {
  width: 100%;
}
.inputAdornment {
  color: $placeholder;
}
//SELECT
select {
  height: 38px;
  min-width: 188px;
  background: $purewhite;
  border: 1px solid $border;
  color: $mediumGrey;
  vertical-align: middle;
  font-size: 1.3rem;
  margin: 8px 0;
}
select:focus {
  outline: none;
}
select.block {
  width: 100%;
}
.reactSelect {
  font-size: 1.6rem;

  .reactSelect__control{
    padding: 6px 8px;
  }

  .reactSelect__control--is-focused {
    border: 1.5px solid $brand;
    box-shadow: none;

    &:hover {
      border: 1.5px solid $brand;
    }
  }
  .reactSelect__menu {
    z-index: 2;
  }
  .reactSelect__indicator-separator {
    display: none;
  }
  .reactSelect__option:hover {
    background-color: $border;
    color: $primary;
  }
  .reactSelect__option--is-focused {
    background-color: $border;
    color: $primary;
  }
  .reactSelect__option--is-selected {
    background-color: $brand;
    color: $purewhite;

    &:hover {
      background-color: $brand;
      color: $purewhite;
    }
  }
}
#reactSelect_placeholder{
  position: absolute;
  top: -8px;
  left: 8px;
  padding: 0 4px;
  background: #fff;
  z-index: 1;
}
.phoneInput{
  position: relative;
  margin-top: 12px;

  .phoneInput_inputField{
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    font-size: 1.6rem;
    font-family: "Proxima Soft";
    border: 1px solid rgb(196, 196, 196);    
    border-radius: 4px;
    font-family: "proxima-soft";

    &:hover{
      border: 1px solid $primary;
    }
    &:focus{
      border: 2px solid $brand;
    }
}
#phoneInputLabel{
  position: absolute;
  top: -2px;
  left: 10px;
  padding: 0 4px;
  background: #fff;
  z-index: 1;
}
}

//INPUT GROUPS (Multiple inline inputs)

.inputGroup .inputGroup_item {
  margin-right: 12px !important;
}
.inputGroup .inputGroup_item:last-child {
  margin-right: 0px !important;
}
.inputGroup .inputGroup_item_inner,
.inputGroup input {
  padding-top: 9px;
  padding-bottom: 8px;
}

.inputGroup button.inputGroup_item {
  vertical-align: top;
}

//FORM GROUPS (Label + input combi)
.formGroup {
  font-size: 1.3rem;
}

//InputPacks (Inputs with icons)
.inputPack.block {
  display: flex;
  width: 100%;
}
.inputPack {
  display: inline-flex;
  margin: 10px 0;
  border-radius: 6px;
  border: 1px solid $border;

  &:focus-within {
    border: 1px solid $lightGrey;

    .inputPack_icon {
      color: $primary;
    }
  }
  .inputPack_icon {
    padding: 16px;
    text-align: center;
    border-radius: 6px 0 0 6px;
    border: 0;
    border-right: 0;
    color: $placeholder;
  }
  .inputPack_input {
    width: 100%;
    padding: 10px;
    outline: none;
    border-radius: 0 6px 6px 0;
    margin: 0;
    border: 0;

    &:focus {
      border: 0;
    }
  }
}
.snackbarContainer{
  min-width: 100px !important;
}
.snackbar {
  &.snackbar_save {
    z-index: 1200;
  }

  > div {
    background: $purewhite;
    min-width: unset;
    padding: 16px 24px 16px 24px;
    color: $primary;
    border-radius: 6px;
    font-size: 1.3rem;

    .snackbarMessage {
      padding-right: 24px;
    }
    .snackbarActions {
      padding-left: 0;
    }
  }
  .snackbar_warning{
    border-left: 12px solid $warning;
  }
  .snackbar_error{
    border-left: 12px solid $danger;
  }
  .snackbar_success {
    border-left: 12px solid $success;   
  }
  .snackbar_default{
    border-left: 12px solid $mediumGrey;
  }
  .snackbar_info {
    border-left: 12px solid $info;
  }
}

//AVATARS

.avatar.small {
  margin-right: 12px;
  width: 35px;
  height: 35px;
}

//FULLSCREEN DIALOG

.paperFullScreen{
  .MuiDialogContent-root:first-child{
    padding: 0;
  }
}
.dialogHeader {
  box-shadow: $dropShadowMedium;
  background: $purewhite;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1;
}
.imageGallery_thumbnail {
  height: 170px;
  background: $border;
  border-radius: 12px;
  position: relative;
  transition: 0.2s all ease-in-out;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  img.disabled {
    pointer-events: none;
    cursor: default;
  }
  button {
    font-size: 1.3rem;
    position: absolute;
    right: 0;
    margin: 6px;
    opacity: 0;
    text-shadow: 1px 1px 0px $purewhite;
  }

  &:hover {
    background: darken($border, 2%);

    button {
      opacity: 1;
    }
  }
}
// REGULAR DIALOG
.dialog {
  .dialogActions {
    padding: 24px;
  }
}
//FULLWIDTH DIALOG MOBILE
.MuiDialog-container{
  .fullWidthDialog.MuiDialog-paperFullWidth{
    @media screen and (max-width: $sm) {
      max-width: calc(100% - 16px);
      margin: 16px;
      width: calc(100% - 32px);
    }
    &.searchPostingDialog{
      @media screen and (max-width: $sm) {
       margin-top: 50px;
      }
    }
  }


}

//SEARCH POSTING SWIPEABLE DRAWER

.drawer_searchPostings{
  border-radius: 24px 24px 0 0;
}
.swipeableDrawer_searchPostings{
  border-radius: 24px 24px 0 0;

  &::after{
    content: " ";
    height: 4px;
    width: 100px;
    background-color: rgba(255,255,255,0.5);
    position: absolute;
    top: 10px;
    margin: 0 auto;
    text-align: center;
    left: 0;
    right: 0;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  }
}

//CHIP STYLING
.postingChipWrapper {
  position: absolute;
  margin-top: -48px;
  .postingChip {
    margin-right: 8px;
    background: $border;
    color: $primary;
  }
}
.chip {
  font-weight: $semibold;
  margin-right: 8px;
  color: $primary;

  .deleteIcon {
    padding: 0 8px;
    height: unset;
    width: unset;
    
  }
}
.checkoutDialog {
  z-index: 1400 !important;
}
//BADGES

.badge .badgeElement {
  color: #fff;
  font-weight: $bold;

  &.badgeDot {
    width: 12px;
    height: 12px;
  }
}
.filterDrawer {
  max-width: 400px;
}
.filterSection {
  border-bottom: 1px solid $border;
  padding-bottom: 16px;

  &:last-child {
    border-bottom: none;
  }
}
#menuToggle {
  padding: 16px 16px 16px 0;
}

//Mobile settings

#mobileSettings_list{
  margin: 0 -16px;
  height: 100vh;
  background: #fff;
  z-index: 900;
  padding-top: 0;
}


// Skeleton style

.MuiSkeleton-root.MuiSkeleton-rect, .MuiSkeleton-root.MuiSkeleton-text{
background: $border;
}
//BADGES

.badgeChip{
  background: rgba($placeholder,0.25);
  color: $placeholder;
  font-weight: 600;
  display: inline-block;
  padding: 2px 8px;
  font-size: 1.1rem;
  border-radius: 4px;

  &.brand{
    background: rgba($brand,0.25);
    color: $brand;
  }
  &.success{
    background: rgba($success,0.25);
    color: $success;
  }
  &.danger{
    background: rgba($danger,0.25);
    color: $danger;
  }
}
