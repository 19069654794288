@import "../../../variables.scss";

.pageLoader_backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 1300;

  &.opaque {
    background: #fff;
  }

  .pageLoader_wrapper {
    position: relative;
    height: 100%;
    text-align: center;
  }
  .pageLoader_icon {
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    position: absolute;
  }
  .errorCountdown {
    font-weight: 900;
    font-size: 10rem;
    color: $brand;
    line-height: 1;
  }
}
// WITH NAVMENU FREE TO ESCAPE

.pageLoader_backdrop.releaseNavbar {
  position: fixed;
  left: 250px;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 1300;

  @media screen and (max-width: $sm) {
    left: 0px;
    bottom: 80px;
  }
}
