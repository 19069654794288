@import '../../../../../variables.scss';
.communityFreelancerView{
    .communityCard {
        box-shadow: $dropShadowLarge;
        height: 100%;
        transition: 0.2s all ease-in-out;
        background-color: $purewhite;
      }
      .cardImage {
        height: 185px;
        background: $border;
        background-size: cover;
    
        &.cardImage_flexibility {
          background-image: url("/img/photos/community/community_flexibility.jpg");
        }
        &.cardImage_communityManager {
          background-image: url("/img/photos/community/community_communityManager.jpg");
        }
        &.cardImage_employment {
          background-image: url("/img/photos/community/community_employment.jpg");
        }
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
          &.cardImage_flexibility {
            background-image: url("/img/photos/community/community_flexibility@2x.jpg");
          }
          &.cardImage_communityManager {
            background-image: url("/img/photos/community/community_communityManager@2x.jpg");
          }
          &.cardImage_employment {
            background-image: url("/img/photos/community/community_employment@2x.jpg");
          }
        }
      }
      .cardTitle {
        font-family: "Merriweather", serif;
        color: $primary;
        font-size: 2.8rem;
      }
      .cardContent {
        font-size: 1.6rem;
        color: $primary;
        line-height: 22pt;
        margin-top: 12px;
      }
}
.communityCompanyView{

  .communityCompany_item {
    position: relative;
    margin-bottom: 32px;
    padding-left: 12px;

    &:before {
      content: "";
      @include horizontal-gradient($gradientStart, $gradientEnd);
      height: 100%;
      width: 4px;
      position: absolute;
      left: 0px;
      border-radius: 4px;
    }
    font-size: 1.6rem;

    .communityCompany_title {
      font-size: 1.6rem;
      font-weight: $bold;
    }
    .communityCompany_content {
      color: $primary;
    }
  }
  #communityImage{
      position: relative;
      left: -50%;
      top: 0;
      border-radius: 12px;
  }
}