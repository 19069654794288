@import "../../../../variables.scss";

.FrontpageFreelancer {
  ::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: $gradientEnd;
  }

  ::selection {
    color: #fff;
    background: $gradientEnd;
  }
  .hero {
    height: calc(100vh - 222px);
    position: relative;
    padding-top: 150px;
    @include horizontal-gradient($gradientStart, $gradientEnd);
    overflow: hidden;

    @media screen and (max-width: $md) {
      height: unset;
      padding-top: 50px;
      padding-bottom: 100px;
     }

    h1.tagline {
      color: $purewhite;
      font-family: "Merriweather";
      font-weight: bold;
      font-size: 4.7rem;
      text-align: left;
      line-height: normal;
      width: 480px;
      margin-bottom: 12px;

      @media screen and (max-width: $md) {
        font-size: 3.7rem;
       }
    }
    .heroContent {
      width: 369px;
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      text-align: left;
      color: $light;
      display: inline-block;
    }
    .heroImage {
      position: absolute;
      top: -5%;
      right: -20%;
      z-index: 0;
      width: 75%;
    }
    #freelancer_heroCTA {
      color: $brand;
      background: $purewhite;
      // font-weight: $bold;
      // text-transform: none;
    }
    #freelancer_secondaryCTA {
      color: $purewhite;
    }
  }
  section {
    padding: 100px 0 50px;
    position: relative;

    &.sectionWithWave {
      padding-top: 30px;
    }
    &.noSectionPadding{
      padding: 0;
    }

  }
  .sectionHeading {
    font-family: "Merriweather", serif;
    font-weight: bold;
    font-size: 3rem;
    line-height: 38pt;
    text-align: center;
    margin: 30px 0 80px;
  }
  .sectionParagraph {
    font-size: 1.6rem;
    line-height: 22pt;
    text-align: center;
    max-width: 480px;
    margin: 0 auto;
  }
  .section_light {
    .sectionHeading {
      color: $darkBackground;
    }
    .sectionParagraph {
      color: $primary;
    }
  }
  .section_dark {
    background: $darkBackground;
    color: $purewhite;
    .sectionHeading {
      color: $purewhite;
    }
    .sectionParagraph {
      color: $purewhite;
    }
  }
  .freelancer_valueProp {
    position: relative;
    margin-bottom: 32px;
    padding-left: 12px;

    &:before {
      content: "";
      @include horizontal-gradient($gradientStart, $gradientEnd);
      height: 100%;
      width: 4px;
      position: absolute;
      left: 0px;
      border-radius: 4px;
    }
    font-size: 1.6rem;

    .freelancer_valuePropTitle {
      font-size: 1.6rem;
      font-weight: $bold;
    }
    .freelancer_valuePropContent {
      color: $primary;
    }
  }
  #valueImage{
    position: relative;
    left: -50%;
    top: -10%;
  }

  .topWave {
    margin-bottom: -10px;
    position: relative;
    left: -1%;
    width: 102%;
  }
  .blob {
    position: absolute;
    z-index: -1;

    &.blobVideo {
      left: -40%;
    }
    &.blobApp {
      left: -30%;
      top: 60%;
    }
    &.blobSegments {
      left: -15%;
      top: -20%;
    }
  }
}
