// Colors
// -------------------------------

$purewhite: #fff;
$light: #f8fafc;
$border: #ECEFF5;
$lightGrey: #B6BFCC;
$mediumGrey: #6b7584;
$placeholder: #9CA6B4;
$primary: #495057;
$darkBackground: #1d2228;

$success: #1DD19A;
$danger: #FF4A51;
$warning: #FACA57;
$brand: #F99D26;
$info: #4798F7;
$favourite: #e84393;

$gradientStart: #F98525;
$gradientEnd: #F99D26;

// Gradients
// -------------------------------
@mixin gradientText {
  background: -webkit-linear-gradient($gradientEnd, $gradientEnd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin horizontal-gradient($from, $to, $stop: 100%) {
  /* fallback/image non-cover color */
  background-color: $from;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, $from),
    color-stop($stop, $to)
  );
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);
  /* IE10+ */
  background: -ms-linear-gradient(left, $from 0%, $to $stop);
  /* Standard */
  background: linear-gradient(135deg, $from 0%, $to $stop);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}

// DROP SHADOWS
// -------------------------------
$dropShadowLarge: 0 3px 24px rgba(0,0,0,0.05);
$dropShadowMedium: 0 3px 12px rgba(0,0,0,0.1);
$dropShadowSmall: 0 3px 6px rgba(0,0,0,0.1);

$dropShadowButton: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
$dropShadowButtonHover: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);


// Images
// -------------------------------
$imageRadius: 80px;

// Menu widths 
// -------------------------------
$mainNavigation: 250px;

// FontWeights
// -------------------------------
$regular: 400;
$semibold: 600;
$bold: 700;


// Breakpoints (Bootstrap)
// -------------------------------
// $xs: 0px;
// $sm: 576px;
// $md: 768px;
// $lg: 992px;
// $xl: 1200px;


// Breakpoints (Material UI)
// -------------------------------
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;