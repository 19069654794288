@import './src/variables.scss';
.blogPost{

    p{
        font-size: 1.6rem;
        line-height: 22px;
    }
    .fr-view blockquote{
        border-left: 0;
        color: $primary;
    }
    blockquote{
        text-align: center;
        font-style: italic;
        position: relative;
        padding: 32px 16px;
        margin-top: 30px;

        &::before{
            content: "";
            background: $brand;
            height: 2px;           
            display: inline-block;
            position: absolute;
            left: 10%;
            right: 10%;
            top: 0;
        }
        &::after{
            content: '"';
            background: #fff;
            color: #f79747;
            position: absolute;
            font-size: 7rem;        
            margin: 0 auto;
            text-align: center;
            top: -3px;
            margin-left: -29px;
            line-height: 0;
            padding: 17px;
        }

    }
    .blogWrapper{
        max-width: 850px;
        margin: 0 auto;
        margin-top: 60px;    
    }
    .blogHeaderImage{
        height: 300px;
        background: $light;
        border-radius: 12px 12px 0 0;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .blogHeaderImage_placeholder{
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: 20px;
        right: 20px;
        border: 1px dashed $lightGrey;
        border-radius: 6px;
        transition: 0.2s all ease-in-out;

        &:hover{
            background: darken($light,2%);
        }
    }
    .blogHeaderImage_placeholder_text{
        text-align: center;
        padding-top: 60px;
        color: $placeholder;
        
        h2{
            color: $placeholder;
        }
    }
    .blogContent{
        padding: 40px 80px;
    }
    .blogHeader{
        margin-bottom: 30px;
    }
    .blogText{    
        font-size: 1.6rem;
    }
    .authorImage{
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
    }
    .nameAndDate{
        vertical-align: middle;
        display: inline-block;

        h6{
            color: $lightGrey;
            text-transform: uppercase;
        }
    }
    .replaceImage{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -60px;
        margin-top: -18px;
        color:#fff;
        border-color: #fff;
        display: none;
        background: rgba(0,0,0,0.5);

        &:hover{
            background: rgba(0,0,0,0.7);
        }
    }
    .blogHeaderImage:hover{
        .replaceImage{
            display: block;
        }

    }
}