@import '../../../../variables.scss';

.freelancerShiftItem{
    position: relative;
    padding: 8px 8px 16px 8px;
    margin: 8px 0;
    border-bottom: 1px solid $border;
    
    .freelancerShiftItem_icon{
        margin-right: 12px;
    }
    .freelancerShiftItem_date{
       text-align: center;
       line-height: 1;
       text-transform: capitalize;

       span{
           font-size: 1.6rem;
       }
    }
    .freelancerShiftItem_image img{
        width: 40px;
        height: 40px;
        background: $border;
        border-radius: 4px;
        margin: 0 12px 0 16px;
        border: 1px solid $border;
    }
    .freelancerShiftItem_details{
        h2{
            line-height: 1;
        }
    }
}