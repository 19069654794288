@import '../../../../variables.scss';

.toggleBlock{
    position: relative;
    border: 1px solid $border;
    border-radius: 6px;
    padding-left: 16px;
    margin-bottom: 16px
}
.toggleBlock_title{
    font-size: 1.3rem;
    line-height: 34pt;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.toggleBlock_switch{
    position: absolute;
    right: 0;
    top: 0;
}