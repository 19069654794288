@import '../../../../variables.scss';
.feedbackComponent{
    .rateSection{
        border-right: 1px solid $border;        
        text-align: center;
        padding-right: 16px;
        margin-top: 12px;
    }
    .payoutBadge{
        font-weight: $bold;
        background: $border;
        color: $primary;       
        padding: 2px 8px;
        font-size: 1.3rem;
        border-radius: 4px;
        float: right;

        &.paymentSuccess{
            color: $success;
            background: rgba($success,0.25);
        }
    }
    button.upvoted{
        i{
            font-weight: 900;
            color: $success;
        }
    }
    button.downvoted{
        i{
            font-weight: 900;
            color: $danger;
        }
    }
    @media screen and (max-width: $sm) {
        .rateSection{
            border-right: 0;
            border-bottom: 1px solid $border;        
            text-align: center;
            padding-right: 0px;
            padding-bottom: 8px;
        }
        .payoutBadge{
            display: block;
            text-align: center;
            margin-top: 16px;
            float: unset;
        }
   }
}
