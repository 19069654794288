@import "../../../../variables.scss";

.applicantProfileWrapper {
  height: 100%;
  box-sizing: border-box;
  .applicantProfile {
    transition: 0.2s all ease-in-out;
    box-shadow: $dropShadowSmall;
    background: $purewhite;
    box-sizing: border-box;
    height: 100%;
  }
  .applicantProfile:hover {
    box-shadow: $dropShadowSmall, $dropShadowLarge;
    cursor: pointer;
    transform: translateY(-1px);
  }
  .applicantProfile_top {
    position: relative;
  }
  .ap_image {
    width: 100%;
    max-height: 100%;
    background: $border;
    border-radius: 6px;
    position: relative;
    display: inline-block;
  }
  .ap_favouriteIndicator {
    color: $favourite;
    margin-left: 8px;
  }
  .ap_name {
    font-weight: $bold;
    font-size: 1.8rem;
    color: $primary;
  }
  .badgeSection {
    margin-top: 5px;
  }
  .ap_badge {
    padding: 2px 6px;
    background: $border;
    display: inline-block;
    font-weight: $semibold;
    margin-right: 5px;
    border-radius: 3px;
    font-size: 1.1rem;
    margin-bottom: 4px;

    &.bestMatch {
      background: rgba($brand, 0.25);
      color: $brand;
    }
    &.previouslyHired {
      background: rgba($success, 0.25);
      color: $success;
    }
    &.nonNativeSpeaker {
      background: rgba($info, 0.25);
      color: $info;
    }
    &.favouriteHired {
      background: rgba($favourite, 0.25);
      color: $favourite;
    }
  }
  .hasCompetence {
    color: $primary;
    display: inline-block;
    padding: 6px;
    border: 1px solid $border;
    border-radius: 4px;
    font-weight: $semibold;
    margin: 4px;
    font-size: 1.1rem;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      margin-right: 4px;
      font-weight: $bold;
      color: $success;
    }
    // &:after{
    //     content: ", ";
    // }
    // &:last-of-type:after{
    //   content: " ";
    // }
  }
  .hasNotCompetence {
    display: inline-block;
    color: $lightGrey;
    filter: blur(0.5px);
    border: 1px solid $border;
    padding: 6px;
    border-radius: 4px;
    font-weight: $semibold;
    margin: 4px;
    background: $light;
    font-size: 1.1rem;

    // &:after{
    //     content: ", ";
    // }
    // &:last-of-type:after{
    //   content: " ";
    // }
  }

  .distance-container {
    display: flex;
  }
}
