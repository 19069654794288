@import '../../../../variables.scss';
.freelancerFavouriteView{

    .favouriteName{
        font-weight: 700;
        font-size: 1.8rem;
        color: $primary;       
    }
    .favouriteImg{
        width: 80px;
        height: 80px;
        border-radius: 6px;
        background: $border;
        margin: 0px auto;
        margin-right: 20px;
    }
    .favouriteRating{
        font-size: 1.3rem;
        color: $warning;
    }
    .editUser{
        position: absolute;
        top: 0;
        right: 0;
        color: $placeholder;
        display: none;
        cursor: pointer;
        padding: 0 0 8px 8px;
    }
    .favouriteItem:hover{
        .editUser{
            display: block;
        }
    }
}