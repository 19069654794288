@import "../../../../variables.scss";

.postingCardWrapper {
  background: #fff;
  box-shadow: $dropShadowLarge;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: all 0.1s cubic-bezier(0.47, 0, 0.745, 0.715);

  &:hover{
    box-shadow: $dropShadowLarge, $dropShadowMedium;
  }
  .PostingCard_cover {
   background: rgba($border,0.8);
   background-size: cover;
    height: 130px;
    border-radius: 8px 8px 0 0;
  }
  .postingCard_content {
    padding: 8px 16px 16px;
    position: relative;
  }
  .salaryIndicator{
    position: absolute;
    right: 0;
    padding: 8px;
    background: rgba(255,255,255,0.9);
    font-weight: $semibold;
    border-radius: 0 7px 0px 8px;
    color: $primary;
  }
  .favouriteIndicator{
    position: absolute;
    top: 5px;
    right: 5px;
    color: $favourite;
  }
  .outer {
    background: $border;
    position: relative;
    border-radius: 6px;
    margin-top: -45%;
    border: 2px solid $light;
    overflow: hidden;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%;
    }
    > .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      img{
        min-width: 100%;
        height: 100%;     
      }
      .ap_image{
        color: #fafafa;
        background: $border;
        font-size: 3.2rem;
        border-radius: 0;
        width: 100%;
        height: 100%;
        font-family: "Proxima-Soft";
        font-weight: 700;

        &.MuiAvatar-colorDefault{
          @include horizontal-gradient($gradientStart, $gradientEnd);
        }
      }
    }
  }
  .companyName {
    font-weight: $bold;
    font-size: 1.6rem;
    color: $primary;
  }
  .freelancersNeeded {
    font-weight: $bold;
    font-size: 1.1rem;
    color: $brand;
    text-transform: uppercase;
  }
}
