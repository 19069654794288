@import '../../../variables.scss';
.cropperPreviewImg{
    width: 120px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: -135px;
    border: 2px solid $purewhite;
    box-shadow: $dropShadowSmall;
}
.cropper-modal.cropper-drag-box{
    background-color: #fff;
}
.imageCropper img{
    max-width: 100%;
}
.cropper-view-box{
    border: 1px dashed $darkBackground;
}
.cropper-line{
    background-color: $primary;
    opacity: 0;
}
.cropper-point{
    background-color: $darkBackground;
}
