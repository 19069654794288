@import "../../../variables.scss";
.document-container {
  margin-bottom: 5px;
  .document-accordion {
    border: 1px solid $lightGrey;
    box-shadow: none !important;
    .accordion-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &__last-updated {
        color: lightgray;
      }
    }
  }
}
