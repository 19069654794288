@import '../../../../../../variables.scss';
.freelanerResume{
    #FreelancerPicture{
        height: 150px;
        width: 150px;
        border-radius: 24px;
        background: $border;
        margin: 20px auto;

        img{
            width: 150px;
            height: 150px;
            overflow: hidden;
            border-radius: 24px;
        }
    }
    .freelancerResume_item{
        margin-bottom: 24px;
        background: $purewhite;
        border-radius: 6px;
        border: 1px solid $border;
        padding: 16px;
        position: relative;
        transition: 0.2s ease-in-out;

        &.error{
            border: 1px solid $danger;
            background: rgba($danger,0.1);
        }

        .freelancerResume_edit{
            position: absolute;
            right: 0;
            top: 0;
            padding: 20px;
            color: $placeholder;  
            cursor: pointer;                 
        }
        .freelancerResume_item_title{
            font-weight: $semibold;
            margin-bottom: 5px;
            font-size: 1.6rem;
        }
        .freelancerResume_item_date{
            font-size: 1.1rem;
            color: $placeholder;
        }        
        .freelancerResume_item_content{
            font-size: 1.6rem;
            color: $mediumGrey;
            margin-top: 5px;
        }
    }
    .competences{
        font-size: 1.3rem;
        display: inline-block;
        margin-right: 8px;

        &:after{
            content:",";
        }
        &:last-child:after{
            content: "";
        }
        &.comp_has{
            color: $brand;
            display: inline-block;
        }
        &.comp_hasNot{
            color: $placeholder;
            display: inline-block;
        }
    } 
    .competenceWrapper.error{
        background: rgba($danger,0.1);
        padding: 20px;
        border-radius: 16px;
        border: 1px solid $danger;
    }
}
ul li.fromListValues:first-child{
        display: none;
} 