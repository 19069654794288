@import '../../../../variables.scss';
.companyUserName{
    font-weight: 700;
    font-size: 1.6rem;
    color: $primary;
    display: inline-block;
    margin-right: 8px;
}
.userStatus_admin{
    background: rgba($success,0.25);
    display: inline-block;
    padding: 6px;
    border-radius: 4px;
    color: $success;
    font-weight: 700;
}
.userStatus_user{
    background: rgba($info,0.25);
    display: inline-block;
    padding: 6px;
    border-radius: 4px;
    color: $info;
    font-weight: 700;
}

.editUser{
    color: $placeholder;
}