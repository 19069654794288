.predefined-templates {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  &__btn {
    width: 200px;
    margin: 5px !important;
    .MuiButton-label {
      justify-content: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
