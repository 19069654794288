@import '../../../../../variables.scss';

.translationPair{
    border-bottom: 1px solid $border;    
}

.global{
    position: relative;
    height: 100%;
    padding-top: 16px;

    &:hover{
        .editGlobal{
            display: block;
        }
    }
    .editGlobal{
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px;
        display: none;
        cursor: pointer;
    }
    .developerNote{
        font-size: 1.1rem;
        color: $placeholder;
    }
}
#untranslatedStrings{
    background: $danger;
    display: inline-block;
    padding: 5px 16px;
    color: $purewhite;
    border-radius: 2px;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 16px;
}
#untranslatedStrings.hidden{
    display: none;
}

textarea.localLanguage{
    height: 100%;
    width: 100%;
    
    textarea.editLocked{
        border: 0;
    }
}
.error > textarea{
    border: 1px solid $danger;
}