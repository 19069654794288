@import '../../../variables.scss';

.styleGuideView{
    background: $purewhite;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .scrollWrapper{
        padding: 80px;
    }
    .styleGuideHeading{
        font-size: 5rem;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 16px;
    }
    .styleGuideSection{
        margin-bottom: 40px;
    }
    .colorBlock{
        width: 85px;
        height: 85px;
        border-radius: 8px;
        display: inline-block;
        margin-right: 20px;

        &.purewhite{
            background: $purewhite;
            border: 1px solid $border;
        }
        &.light{
            background: $light;           
        }
        &.border{
            background: $border;           
        }
        &.lightGrey{
            background: $lightGrey;           
        }
        &.placeholder{
            background: $placeholder;           
        }
        &.mediumGrey{
            background: $mediumGrey;           
        }
        &.primary{
            background: $primary;           
        }
        &.darkBackground{
            background: $darkBackground;           
        }
        &.brand{
            background: $brand;           
        }
        &.success{
            background: $success;           
        }
        &.warning{
            background: $warning;           
        }
        &.danger{
            background: $danger;           
        }
        &.brandGradient{
            @include horizontal-gradient($gradientStart, $gradientEnd);    
        }
    }
    .paddingBlock{
        width: 40px;
        height: 40px;
        background: $border;
        box-sizing: content-box;
        margin-right: 32px;
        border: solid #C0EDB5
    }
    .marginBlock{
        width: 40px;
        height: 40px;
        background: $border;
        box-sizing: content-box;
        margin-right: 32px;
        border: solid #FFCCA0;
    }
    .type-top{            
        border-width: 0px 16px 16px 16px;
    }
    .type-right{
        border-width: 16px 0px 16px 16px;
    }
    .type-bottom{            
        border-width: 16px 16px 0px 16px;
    }
    .type-left{            
        border-width: 16px 16px 16px 0px;
    }
    .type-0{            
        border-width: 0px;
    }
    .type-8{            
        border-width: 8px;
    }
    .type-16{            
        border-width: 16px;
    }
    .type-24{            
        border-width: 24px;
    }
    .type-32{            
        border-width: 32px;
    }
    .type-40{            
        border-width: 40px;
    }
    .radiusBlock{
        width: 85px;
        height: 85px;
        background: #1d2228;
        margin-right: 24px;

        &.rad-0{
            border-radius: 0;
        }
        &.rad-4{
            border-radius: 4px;
        }
        &.rad-6{
            border-radius: 6px;
        }
        &.rad-12{
            border-radius: 12px;
        }
    }
    .shadowBlock{
        width: 85px;
        height: 85px;
        background: #fff;
        margin-right: 24px;

        &.shadow-large{
            box-shadow: $dropShadowLarge;
        }
        &.shadow-medium{
            box-shadow: $dropShadowMedium;
        }
        &.shadow-small{
            box-shadow: $dropShadowSmall;
        }
    }
    .hr-20{
        border: solid #FFCCA0;
        background: $border;
        height: 1px;
        border-width: 20px 0 20px 0px;
        width: 100%;
        display: block;
        box-sizing: content-box;
        margin-bottom: 20px;
    }
    .hr-40{
        border: solid #FFCCA0;
        background: $border;
        height: 1px;
        border-width: 40px 0 40px 0px;
        width: 100%;
        display: block;
        box-sizing: content-box;
        margin-bottom: 20px;
    }

}

