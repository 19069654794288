@import "../../../../../variables.scss";

.segmentPage {
  //hero section
  .hero {
    height: calc(100vh - 187px);
    padding-top: 100px;
    margin-bottom: 100px;

    @media screen and (max-width: $md) {
       height: unset;
       padding-top: 0;
      }
    h1.tagline {
      color: $darkBackground;
      font-family: "Merriweather";
      font-weight: bold;
      font-size: 4.7rem;
      text-align: left;
      line-height: normal;
      width: 480px;
      margin-bottom: 12px;
    }
    .heroContent {
      width: 369px;
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      text-align: left;
      color: $mediumGrey;
      display: inline-block;
    }
    .heroImage {
      position: absolute;
      top: 0;
      left: 42%;
      height: 850px;
    }
    .heroImage_mobile{
        width: 100%;
    }

  }
  section {
    padding: 100px 0 50px;
  }
  .sectionHeading {
    font-family: "Merriweather", serif;
    font-weight: bold;
    font-size: 3rem;
    line-height: 38pt;
    text-align: center;
    margin: 30px 0 80px;
  }
  //ValueProps
  .valueProp {
    text-align: left;

    .valueProp_Icon {
      color: $brand;
    }
    .valueProp_Title {
      font-family: "Merriweather", serif;
      color: $primary;
      font-size: 2.6rem;
      margin: 12px 0 0;
    }
    .valueProp_Content {
      font-size: 1.6rem;
      color: $primary;
      line-height: 22pt;
      margin-top: 0;
    }
  }

  //VideoCase
  .videoCase {
    padding: 60px 40px;

    .caseTitle {
      font-family: "Merriweather";
      font-weight: bold;
      font-size: 30px;
      text-align: left;
      color: $primary;
      margin-bottom: 32px;
    }
    .caseContent {
      font-size: 1.6rem;
      line-height: 32px;
      text-align: left;
      color: $primary;
    }
  }
  .videoPoster {
    background: $border;
    height: 100%;
    position: relative;
    min-height: 300px;

    &.caseOne {
      background: url(../../../../../assets/img/photos/case1.jpg) top center
        no-repeat;
      background-size: cover;
    }
    &.caseTwo {
      background: url(../../../../../assets/img/photos/case2.jpg) top center
        no-repeat;
      background-size: cover;
    }
    &.caseThree {
      background: url(../../../../../assets/img/photos/case3.jpg) top center
        no-repeat;
      background-size: cover;
    }
    .videoPoster_playIcon {
      color: #fff;
      opacity: 0.8;
      position: absolute;
      top: calc(50% - 32px); //half the icon height of 65px
      left: calc(50% - 32px); //half the icon width of 65px
      transition: 0.2s ease-out;
      cursor: pointer;

      &:hover {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
  .blob {
    position: absolute;
    z-index: -1;

    &.blobValue {
      right: -18%;
    }
    &.blobCase {
      left: -40%;
    }
    &.blobFeatures {
      right: -30%;
    }
  }
}
