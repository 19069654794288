@import '../../../variables.scss';

.drawerContent{
    width: 450px;
    padding: 20px;
}
button.deleteBlogPost{
    font-size: 1.3rem;
    position: absolute;
    right: 0;
    top: 0;
}