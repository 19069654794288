@import "../../../../variables.scss";
.verificationWrapper {
  .verifyInput {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  .verifyInput input {
    width: 30px;
    text-align: center;
    font-size: 3rem;
  }

}
.bottomLink{
    position: absolute;
    bottom:20px;
    left: 0;
    right: 0;
}

