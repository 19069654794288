@import "../../../../variables.scss";
.Footer {
    .topWave {
        margin-bottom: -10px;
        position: relative;
        left: -1%;
        width: 102%;
      }
      .section_dark {
        background: $darkBackground;
        color: $purewhite;
        padding: 16px 0 40px;

        .footerHeading {
            font-family: "Merriweather", serif;
            font-weight: bold;
            font-size: 3rem;
            line-height: 38pt;
            text-align: center;
            margin: 30px 0 40px;
            color: $purewhite;
          }
          .footerParagraph {
            font-size: 1.6rem;
            line-height: 22pt;
            text-align: center;
            max-width: 480px;
            margin: 0 auto;
            color: $purewhite;
          }
      }
      .bottomCTAGroup {
        max-width: 480px;
        margin: 24px auto;
        height: 50px;
        vertical-align: top;
  
        #frontpageCTA_inputWrapper {
          width: 80%;
          display: inline-block;
          box-sizing: border-box;
          vertical-align: top;
          text-align: left;
        }
        #frontpageCTA_input {
          background: #fff;
          height: 50px;
          box-sizing: border-box;
          vertical-align: top;
          margin-top: 0;
          margin-right: 12px;
          width: calc(100% - 12px);
          text-align: left;
          border-radius: 4px;
        }
        #frontpageCTA_signUp {
          @include horizontal-gradient($gradientStart, $gradientEnd);
          color: #fff;
          display: inline-block;
          height: 100%;
          box-sizing: border-box;
          padding: 14px 16px;
          font-weight: $bold;
          border-radius: 4px;
          width: 20%;
          cursor: pointer;
          border: 0;
          margin-top: 0;
  
          &:hover {
            opacity: 0.9;
          }
        }
      }
  .mainFooter {
    background: $darkBackground;
    min-height: 250px;
    color: $purewhite;
  }
}
