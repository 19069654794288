@import '../../../../../variables.scss';
.editPostingView{
    .salaryIntervalRow{
        position: relative;
        border-top: 1px solid #ddd;
        padding: 0;
        margin: 0;
        &.no-margin{
            padding: 0;
        margin: 0;
        }
    }
    .removeSalaryInterval{
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        padding: 4px;
        color: $placeholder;
    }
    // TEMPLATE STYLING 
    &.templateMode{
        .MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline{
            border: 1px dashed $primary;

            label{
                color: $brand;
            }
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
            border: 1px dashed $brand;

            label{
                color: $brand;
            }
        }
        .MuiOutlinedInput-root-214.MuiOutlinedInput-disabled-216 .MuiOutlinedInput-notchedOutline-221{
            border: 1px dashed $border;

            label{
                color: $placeholder;
            }       
        }
        .MuiFormLabel-root-200.MuiFormLabel-disabled-202{
            color: $placeholder;
        }
        label{
            color: $brand;
        }
    }
    
}
.warning {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    color: $brand;
  }
.templateDrawerWrapper{
    .templateDrawer{
        width: 500px;    
    }
    .templateItem{
        background: #fff;
        padding: 24px;
        box-shadow: $dropShadowMedium;
        transition: 0.2s all ease-in;
        margin-bottom: 24px;
        &:hover{
            transform: translateY(-2px);            
            cursor: pointer;
        }
        &.newTemplate{
            padding: 16px;
            text-align: center;
            color: $placeholder;
            border: 2px dashed $border;
            box-shadow: none;
            cursor: pointer;
            transition: 0.2s all ease-in-out;
            &:hover{
                color: $mediumGrey;
                border: 2px dashed $placeholder;
                box-shadow: $dropShadowSmall;
            }
        }
        .templateTitle{
            font-weight: $bold;
            color: $brand;
            text-transform: uppercase;
            margin-bottom: 16px;
        }
        .templateItemRow{
            margin: 8px 0;

            i{
                margin-right: 8px;
            }

            &:last-child{
                margin-bottom: 32px;
            }
        }
        .templateAvatar{
            background: $placeholder;
            font-weight: $bold;
            font-size: 1rem;
            width: 13px;
            height: 13px;
            text-transform: uppercase;
        }
        .templateIcon{
            color: $placeholder;
            &.active{
                color: $brand;
                .templateAvatar{
                    background: $brand;                    
                }
            }

        }


    }

}
