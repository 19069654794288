.rule-input-container {
    display: flex;

    .toolbar-trigger-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .MuiButtonBase-root {
            margin-bottom: 12px;
        }
    }
}