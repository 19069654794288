@import "../../../../variables.scss";
.postingFull {
  font-size: 1.4rem;
  text-align: left;
  .postingFull_cover {
    height: 300px;
    background: $border;
    background: $border url("../../../../assets/img/DefaultCoverImages/21.jpg")
      no-repeat;
    background-size: cover;
    @media screen and (max-width: $sm) {
      height: 150px;
    }
  }
  .postingFull_top {
    padding: 24px 32px 0 32px;
    position: relative;
    @media screen and (max-width: $sm) {
      padding: 24px 16px 0 16px;
    }
  }
  .postingFull_content {
    padding: 32px;
    @media screen and (max-width: $sm) {
      padding: 16px;
    }
  }
  .postingFull_actionButtons {
    position: absolute;
    top: 0;
    right: 0;
  }
  .postingFull_image {
    width: 150px;
    height: 150px;
    background: $border;
    border-radius: 12px;
    display: inline-block;
    vertical-align: top;
    margin-right: 24px;
    border: 3px solid $light;
    margin-top: -75px;

    @media screen and (max-width: $sm) {
      width: 100px;
      height: 100px;
    }
    &.MuiAvatar-colorDefault{
      color: #fafafa;
      @include horizontal-gradient($gradientStart, $gradientEnd);
      font-size: 4.2rem;     
      font-family: "Proxima Soft";
      font-weight: 700;
      line-height: 150px;
      text-align: center;

      @media screen and (max-width: $sm) {
        line-height: 100px;
      }
    }
  }
  .postingFull_info {
    display: inline-block;
  }
  .postingFull_name {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.6rem;
    font-weight: $bold;
  }
  .postingFull_rating {
    margin-bottom: 12px;
    color: $warning;
    font-size: 1.6rem;
    display: inline-block;
  }
  .favouriteIndicator{
    color: $favourite;
    display: inline-block;
  }
  .postingFull_badgeWrapper {
    margin-top: 22px;
  }
  .postingFull_badge {
    display: inline-block;
    padding: 8px;
    font-weight: $bold;
    background: $placeholder;
    border-radius: 2px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
    &.previouslyHired {
      background: rgba($success, 0.25);
      color: $success;
    }
  }
  .postingFull_Section {
    margin: 24px 0;
  }
  .postingFull_SectionTitle {
    color: $brand;
    font-size: 1.8rem;
  }
  .postingFull_SectionContent {
    padding-left: 55px;
    padding-bottom: 16px;
    font-size: 1.6rem;
    margin-top: 16px;
    color: $primary;

    @media screen and (max-width: $sm) {
      padding-left: 24px;
     }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .Section_title {
      font-weight: $semibold;
      margin-bottom: 5px;
    }
    .Section_date {
      font-size: 1.1rem;
    }
    p {
      font-size: 1.6rem;
      color: $primary;

      &.small {
        font-size: 1.1rem;
      }
    }
  }
  .postingFull_JobDescription {
    white-space: pre-line;
    margin-left: 5px;
    max-height: 100px;
    overflow-y: scroll;
    overflow: -moz-scrollbars-vertical; 
  }
  .postingFull_JobDescription::-webkit-scrollbar {
    -webkit-appearance: none;  
  }
  .postingFull_JobDescription::-webkit-scrollbar:vertical {
    width: 11px;
  }
  .postingFull_JobDescription::-webkit-scrollbar:horizontal {
      height: 11px;
  }
  .postingFull_JobDescription::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }
  .custom-div {
    margin-right: 17px;
  }
  .info-text {
    font-size: 1.6rem;  
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .job-description-icon {
    justify-content: flex-start;
    margin-top: 4px;
  }

  .collective-agreement-agreement-accordion__details {
    display: block;
    max-height: 300px;
    overflow-y: visible;
  }
}

