@import './src/variables.scss';

.adminUserAvatar{
width: 150px;
height: 150px;
background: $lightGrey;
display: inline-block;

    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        vertical-align: middle;
    }
}
.AdminPageAvatar img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    vertical-align: middle; 
}