@import './variables.scss';

.container{ 
    // width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) { 
      .container{
        width: 100%;
    } 
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .container{
      max-width: 750px;
    }
   }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {     
      .container{
      max-width: 970px;
    }
  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
      .container{
        max-width: 1170px;
    } 
  }
  // XXL large devices (large desktops, 1440px and up)
  @media (min-width: 1500px) { 
    .container{
      max-width: 1440px;   
  } 
  }
  .containerFluid{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
@media (min-width: $xs) { 
  .visible-xs{
    display: none !important;
   }
   .hidden-xs{
    display: block !important;
   }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: $sm) { 

}
// Medium devices (tablets, 768px and up)
@media (min-width: $md) { 

 }
// Large devices (desktops, 992px and up)
@media (min-width: $lg) {

 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $xl) { 

 }


 // Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .visible-xs{
    display: block !important;
   }
   .hidden-xs{
    display: none !important;
   }
 }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 

 }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
  
 }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 

 }

// Extra large devices (large desktops)
// No media query since the extra-large breakpoint has no upper bound on its width