@import '../../../../../../variables.scss';
.companyStepProfile{
    #companyProfilePicture{
        height: 300px;
        width: 300px;
        border-radius: 24px;
        background: $border;
        margin: 20px auto;

        img{
            height: 300px;
            width: 300px;
            border-radius: 24px;
        }
    }
    #companyCoverPicture{
        width: 100%;
        border-radius: 12px;
        background: #ECEFF5;
        margin: 20px auto;
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;

        img{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;     
        }
    }
}
