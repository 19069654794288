@import './src/variables.scss';
.atlasMenu{
    position: fixed;
    top: 0;
    bottom: 0;
    left: -70px;
    display: inline-block;
    width: 70px;
    background-image: linear-gradient($gradientStart, $gradientEnd);
    transition: all 0.2s ease-in-out;
    will-change: transform;
    z-index: 10;
    user-select: none;
}
.atlasMenuContent{
    position: relative;
    display: inline-block;
    width: 70px;
    height: 100%;
    vertical-align: top;
}
.menu-primary__links{
position: absolute;
    top: 3%;
    left: 0;
    -webkit-transform: translate3d(0, -3%, 0);
    transform: translate3d(0, -3%, 0);
}
.menu-primary__link {
    position: relative;
    display: inline-block;
    height: 70px;
    line-height: 70px;
    white-space: nowrap;
}
.menu-primary__link-inner {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 15px;
    vertical-align: middle;
    color: $purewhite;
    text-align: center;
    border-radius: 12px;
    transition: color 0.3s ease, background-color 0.3s ease;
}
.menu-primary__link-icon {
    display: inline-block;
    font-size: 18px;
    vertical-align: middle;
    position: relative;
}
.menu-primary__link-label {
    position: absolute;
    left: 100%;
    top: 50%;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    margin-top: -13px;
    margin-left: 10px;
    background: $purewhite;
    color: $darkBackground;
    padding: 0 7px;
    border-radius: 6px;
    font-size: 11px;
    white-space: nowrap;
    opacity: 0;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    pointer-events: none;
    transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
}
.menu-primary__link-label:before {
    position: absolute;
    top: 50%;
    left: -3px;
    margin-top: -3px;
    content: "";
    display: inline-block;
    border-top: 3px solid transparent;
    border-right: 3px solid #FFF;
    border-bottom: 3px solid transparent;
}


.menu-primary__link:hover .menu-primary__link-inner, .menu-primary__link_active .menu-primary__link-inner {
    color: $purewhite;
    background-color: rgba(255, 255, 255, 0.1);
}
.menu-primary__link:hover .menu-primary__link-label {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.menu-primary__link.active .menu-primary__link-inner{
    background-color: rgba(255, 255, 255, 0.1);
}
.menu-primary__footer{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}
.menu-primary__menu {
    background: $purewhite;
    box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    min-width: 200px;
}
.menu-primary__user-menu {
    position: absolute;
    bottom: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transition: transform 0.3s ease, opacity 0.3s ease, -webkit-transform 0.3s ease;
    pointer-events: none;
}
.menu-primary__menu-section {
    padding: 10px 0;
    border-bottom: 1px solid $border;
}
.menu-primary__menu-section:last-child {
    border-bottom: 0;
}
.menu-primary__menu-section-title {
    color: $lightGrey;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px 26px;
    line-height: normal;
    text-align: left;
}
.menu-primary__menu-item, .menu-primary__menu-item:visited, .menu-primary__menu-item:hover {
    color: $mediumGrey;
    text-decoration: none;
}
.menu-primary__menu-item {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 26px;
    font-size: 11px;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    transition: color 0.3s ease;
}
.menu-primary__menu-item-icon {
    display: inline-block;
    font-size: 18px;
    margin-right: 16px;
    vertical-align: middle;
    width: 14px;  
    height: 14px;
}
.menu-primary__link-icon_indicator{
    position: absolute;  
    width: 12px;
    height: 12px;
    background-size: contain;
    bottom: 6px;
    right: -2px;
    border-radius: 50%;

    &.DK{
        background-image: url(../../../../assets/img/flags/da.svg);
    }
    &.NO{
        background-image: url(../../../../assets/img/flags/no.svg);
    }
    &.SE{
        background-image: url(../../../../assets/img/flags/sv.svg);
    }
    &.NL{
        background-image: url(../../../../assets/img/flags/nl.svg);
    }
    &.AU{
        background-image: url(../../../../assets/img/flags/au.svg);
    }
}
.icon-flag-da, .icon-flag-se, .icon-flag-no{
    width: 14px;
    height: 14px;
    border-radius: 50%;
}
.icon-flag-DK{
    background-image: url(../../../../assets/img/flags/da.svg);
}
.icon-flag-NO{
    background-image: url(../../../../assets/img/flags/no.svg);
}
.icon-flag-SE{
    background-image: url(../../../../assets/img/flags/sv.svg);
}
.icon-flag-NL{
    background-image: url(../../../../assets/img/flags/nl.svg);
}
.icon-flag-AU{
    background-image: url(../../../../assets/img/flags/au.svg);
}
.menu-primary__user:hover .menu-primary__user-menu {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
pointer-events: auto;
}
.menu-primary__menu-item:hover {
    color: $primary;
    background: $light;
}
